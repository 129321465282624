import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyright from '../utils/Copyright'


const useStyles = makeStyles((theme) => ({
    footerContainer: {
        padding: theme.spacing(1, 3),
        backgroundColor:
            // theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
            theme.palette.appFooter.main,
    },
    footer: {
        // padding: theme.spacing(2, 1),
        padding: 0,
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
        marginTop: 'auto',
        // backgroundColor:
        //   theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      },
}))


const FooterApp = () => {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg" className={classes.footerContainer}>
                <Typography variant="body1" align="center">It is health that is real wealth and not pieces of gold and silver.</Typography>
                <Copyright />
            </Container>
        </footer>
    )
}
export default FooterApp