import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';

import { baseApiUrl } from '../../utils/appLinks';
import Moment from 'moment';


const useStyles = makeStyles((theme) => ({
  rootTipCard: {
    // maxWidth: 345,
    height: '100%',
    display:'flex',
    flexDirection: 'column',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },

  avatar: {
    backgroundColor: red[500],
  },
  content: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  // grow: {
  //   flexGrow: 1,
  // },
}));

export default function TipCard({ tip }) {
  const classes = useStyles();


  return (
    <Card className={classes.rootTipCard}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Tip
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
       
        title={ <Typography variant="h6">{tip.title}</Typography>}
        subheader= {Moment(tip.createdOn).format('ddd MM/DD/YYYY')}
        // subheader={<Moment format="YYYY/MM/DD">{tip.createdOn}</Moment>}
      />
      <CardMedia
        className={classes.media}
        image={baseApiUrl + tip.healthTipImage}
        title={tip.title}
      />
      <CardContent className={classes.content} >
        <Typography variant="body2" color="textSecondary" component="p" display="inline">
          {tip.content}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton disabled aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton disabled aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}