// import SvgIcon from '@material-ui/core/SvgIcon'

function ExcelDownloadIcon2(props) {
    return (
        <svg
          {...props}
          className="prefix__svg-icon"
          style={{
            width: "1em",
            height: "1em",
            verticalAlign: "middle",
          }}
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          overflow="hidden"
          width="1em"
          height="1em"
        >
          <path
            d="M160 32c-12 0-24.8 4.8-33.6 14.4S112 68 112 80v864c0 12 4.8 24.8 14.4 33.6C136 987.2 148 992 160 992h704c12 0 24.8-4.8 33.6-14.4C907.2 968 912 956 912 944V304L640 32H160z"
            fill="#007a23"
          />
          <path
            d="M912 304H688c-12 0-24.8-4.8-33.6-14.4-9.6-8.8-14.4-21.6-14.4-33.6V32l272 272z"
            fill="#00a03b"
          />
          <path
            d="M475.2 537.6l-108.8-152h75.2l71.2 108.8 74.4-108.8H660l-111.2 152 116.8 161.6h-76L511.2 584l-78.4 116h-74.4l116.8-162.4z"
            fill="#FFF"
          />
        </svg>
      )
  }
  

export default ExcelDownloadIcon2