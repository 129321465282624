import { combineReducers } from 'redux'
import fullPageReducer from './fullPage'
import tokenReducer from './token'
import tokenVerifyReducer from './tokenVerify'
import isHealthTipCreatedReducer from './isHealthTipCreated'
const allReducers = combineReducers({
    fullPage: fullPageReducer,
    token: tokenReducer,
    healthTipCreated: isHealthTipCreatedReducer,
    tokenVerify: tokenVerifyReducer,
});

export default allReducers;