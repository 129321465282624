import React from 'react'
import { userLogOut } from '../../../actions';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme2  } from './stylesSignReg';
import { singInObject } from '../appLinks'

const SingOut = () => {
    const dispatch = useDispatch();
    
    const handleMenuCloseSingOut = () => {
        window.localStorage.removeItem(String(singInObject.singInInfo));
        setTimeout(() => dispatch(userLogOut()), 100);
      }

    return (
        <div>
             <p>You are already logged in, if you want to log out click on the button 
                <ThemeProvider theme={theme2}><Button variant="contained" color="primary" 
                onClick={handleMenuCloseSingOut} style={{ marginLeft: '30px' }}>Sing Out</Button></ThemeProvider></p>  
        </div>
    )
}

export default SingOut


