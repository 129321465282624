const isHealthTipCreatedReducer = (state = false, action) => {
    switch(action.type){
        case 'HEALTH_TIP_CREATED_YES':
            state = true;
            return state;
        case 'HEALTH_TIP_CREATED_NO':
            state = false;
            return state;
        default:
            return state;
    }
};
export default isHealthTipCreatedReducer;