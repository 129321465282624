import { tokenObject } from '../components/utils/details'
const defaultAction = {
    type: 'LOG_OUT',
    payload: {
        "jwt": "",
        "username": "",
        "admin": false,
        "singIn": false
      }
  }


function tokenReducer(state = tokenObject, action=defaultAction ) {

    switch (action.type) {
        case 'LOG_IN':
            if (action.payload === undefined) {
                return state;
            }
            return action.payload;
        case 'LOG_OUT':
            return tokenObject;
        default:
            return state;
    }
}

export default tokenReducer;