import { useState, useEffect } from 'react';

import { /*useHistory, */ Redirect } from "react-router-dom";


import { useDispatch, useSelector } from 'react-redux';
import { fullPageYes, fullPageNo } from '../../../actions';

import Copyright from '../../utils/Copyright';
import UserRegiLogPassHead from '../../utils/UserRegiLogPassTitle';

import { Link as RouterLink } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

import { stylesSignReg } from '../../utils/themes/stylesSignReg';
import Container from '@material-ui/core/Container';

// import { dataLoaderPost } from '../../utils/dataLoader';
import { apiEventService } from '../../utils/api/apiEventService';
import { appLinks } from '../../utils/appLinks'

const formInitail = {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    reEnterPassword: "",
    promotions: false,
}

const formErrorInitail = {
    username: {
        status: false,
        message: <>Please username with at least 6 characters</>,
        errorMessage: <>Username should have at least 6 characters</>
    },
    firstName: {
        status: false,
        message: <>Please your First name</>,
        errorMessage: <>First nameis a required field </>
    },
    email: {
        status: false,
        message: <>Please your email</>,
        errorMessage: <>The password has to be 8 characters long.</>
    },
    password: {
        status: false,
        message: <>Please a password that has at least 8 characters</>,
        errorMessage: <>The password has to be 8 characters long.</>
    },
    reEnterPassword: {
        status: false,
        message: <>Please re-enter your password</>,
        errorMessage: <>Please make sure your password match.</>
    },
}

const RegisterPage = () => {
    const classes = stylesSignReg();

    // let history = useHistory();

    const dispatch = useDispatch();
    const [isUserLogin, setIsUserLogin] = useState(false);
    const getToken = useSelector(state => state.token);

    const [form, setForm] = useState(formInitail);
    const [formError, setFormError] = useState(formErrorInitail);
    const [formSubmit, setFormSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [userCreated, setUserCreated] = useState(false)
    const [usernameEmail, setUsernameEmail] = useState({
        maskEmail: '',
        username: '',
        set: false,
    });

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }


    // const registeRequest = (data, url) => {
    //     return dataLoaderPost(data, url);
    // }

    async function handleSubmit(event) {
        setFormSubmit(true);
        let errorUsername, errorFrstName, errorEmail, errorPassword, errorReEnterPassword = false;
        let sendRequest = true;
        event.preventDefault();
        if (form.username.length < 6) {
            errorUsername = true
            sendRequest = false;
        }
        if (form.firstName.length < 2) {
            errorFrstName = true;
            sendRequest = false;
        }
        if (!validateEmail(form.email)) {
            errorEmail = true;
            sendRequest = false;
        }
        if (form.password.length < 8) {
            errorPassword = true;
            sendRequest = false;
        }
        if (form.password !== form.reEnterPassword) {
            errorReEnterPassword = true;
            sendRequest = false;
        }
        setFormError({
            username: {
                status: errorUsername,
                message: errorUsername ? formErrorInitail.username.errorMessage : <>&nbsp;</>,
            },
            firstName: {
                status: errorFrstName,
                message: errorFrstName ? formErrorInitail.firstName.errorMessage : <>&nbsp;</>
            },
            email: {
                status: errorEmail,
                message: errorEmail ? formErrorInitail.email.errorMessage : <>&nbsp;</>,
            },
            password: {
                status: errorPassword,
                message: errorPassword ? formErrorInitail.password.errorMessage : <>&nbsp;</>,
            },
            reEnterPassword: {
                status: errorReEnterPassword,
                message: errorReEnterPassword ? formErrorInitail.reEnterPassword.errorMessage : <>&nbsp;</>,
            }
        });
        // send data to server
        if (sendRequest) {
            // let userReg = await registeRequest(form, 'http://localhost:8080/v1/rest/members');
            let userReg = await apiEventService.auth.addAppUser({ data: form });
           
            if (userReg === undefined) {
                setErrorMessage('Oops, something is wrong. Please try again later. ');
            } else if (userReg.data === "") {
                setErrorMessage(userReg.error.message);
            } else if (userReg.data !== '') {
                setUsernameEmail({
                    maskEmail: userReg.data.maskEmail,
                    username: form.username,
                    set: true,
                })

                setForm(formInitail);
                setErrorMessage('');
                // history.push(appLinks.signInLink);

                // setUserCreated(true)
            } else {
                setErrorMessage('Oops, something is wrong. Please try again later. ');
            }
        }
    } // end handleSubmit

    let handleChange = e => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        if (formSubmit) {
            // console.log(' - ' + e.target.name + " - " + e.target.value.length);
            if (e.target.name === "username") {
                e.target.value.length > 5 ? setFormError({ ...formError, username: { status: false, message: <>&nbsp; </> } })
                    : setFormError({ ...formError, username: { status: true, message: formErrorInitail.username.errorMessage } });
            } else if (e.target.name === "firstName") {
                e.target.value.length > 1 ? setFormError({ ...formError, firstName: { status: false, message: <>&nbsp; </> } }) :
                    setFormError({ ...formError, firstName: { status: true, message: formErrorInitail.firstName.errorMessage } });
            } else if (e.target.name === "email") {
                validateEmail(e.target.value) ? setFormError({ ...formError, email: { status: false, message: <> &nbsp;</> } }) :
                    setFormError({ ...formError, email: { status: true, message: formErrorInitail.email.errorMessage } });
            } else if (e.target.name === "password") {
                e.target.value.length > 7 ? setFormError({ ...formError, password: { status: false, message: <> &nbsp;</> } }) :
                    setFormError({ ...formError, password: { status: true, message: formErrorInitail.password.errorMessage } });
            } else if (e.target.name === "reEnterPassword") {
                e.target.value === form.password ? setFormError({ ...formError, reEnterPassword: { status: false, message: <> &nbsp;</> } }) :
                    setFormError({ ...formError, reEnterPassword: { status: true, message: formErrorInitail.reEnterPassword.errorMessage } });
            }
        }

    } //end handleChange

    // handle change checkbox
    const handleChangeCheckbox = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        });
    };

    useEffect(() => {
        getToken.jwt === "" ? setIsUserLogin(false) : setIsUserLogin(true);
        if (getToken.jwt === "") {
            setIsUserLogin(false);
            dispatch(fullPageNo());
        } else {
            setIsUserLogin(true);
            dispatch(fullPageYes());
        }
    }, [getToken, dispatch])




    if (isUserLogin) {
        return (
            <h3>you are login olredy</h3>
        );
    } else if (usernameEmail.set) {
        return (
            <Redirect to={{ pathname: appLinks.verificationPageLink, state: { maskEmail: usernameEmail.maskEmail, username: usernameEmail.username } }} />
        )
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <UserRegiLogPassHead headCaption="Sign Up" />
                <div style={{ height: '75px', padding:'2px 6px' }}>
                    {errorMessage === '' ? '' : <Alert severity="warning">{errorMessage}</Alert>}
                </div>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="username"
                                label="User name"
                                name="username"
                                value={form.username}
                                autoComplete={form.username}
                                onChange={handleChange}
                                error={formError.username.status}
                                helperText={formError.username.message}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                value={form.firstName}
                                onChange={handleChange}
                                error={formError.firstName.status}
                                helperText={formError.firstName.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                                value={form.lastName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={formError.email.status}
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={form.email}
                                onChange={handleChange}
                                helperText={formError.email.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={form.password}
                                onChange={handleChange}
                                error={formError.password.status}
                                helperText={formError.password.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="reEnterPassword"
                                label="Re-enter password "
                                type="password"
                                id="reEnterPassword"
                                autoComplete="current-password"
                                value={form.reEnterPassword}
                                onChange={handleChange}
                                error={formError.reEnterPassword.status}
                                helperText={formError.reEnterPassword.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                checked={form.promotions}
                                control={<Checkbox value="allowExtraEmails" name="promotions" color="primary" />}
                                label={<Typography variant="body2">I want to receive inspiration, marketing promotions and updates via email.</Typography>}
                                value={form.promotions}
                                onChange={handleChangeCheckbox}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign Up
              </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link component={RouterLink} to={appLinks.signInLink} variant="body2">
                                Already have an account? Sign in
                  </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default RegisterPage;