
import { makeStyles } from '@material-ui/core/styles'
import BuildIcon from '@material-ui/icons/Build'
import Avatar from '@material-ui/core/Avatar'
import Container from '@material-ui/core/Container'
import { pink } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& > *': {
            //  margin: theme.spacing(1),


        },
        padding: '10px 50px',

    },
    buildWebsite: {
        textAlign: 'center',
    },
    title: {
        display: 'inline',
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
        // margin: 'auto',
        margin: 10,
    },

}));


const BuildWebsite = ({ pageName = '' }) => {
    const classes = useStyles();
    return (
        <div className={classes.buildWebsite}>


            <div className={classes.root}>


                <Avatar className={classes.pink}>
                    <BuildIcon />
                </Avatar>
                <h3>

                    {pageName === '' ? '' : pageName + ' page - '}Oops, that page is in the making stage
                  </h3>

            </div>
            <Container maxWidth="sm">
                <img alt='No Content' src={process.env.PUBLIC_URL + '/images/buildWebsite.webp'} style={{width: '100%'}} />
            </Container>


        </div>
    )
}

export default BuildWebsite
