import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useDropzone } from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({

    drop: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: 'thin',
        borderRadius: '4px',
        borderStyle: 'solid',
        margin: '0',

        //     border-color: red;
        // border-width: thick;
        // border-style: solid;
        // padding: 20px;
        // margin: 10px;
    },

    dropzone: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '4px',
        borderRadius: '2px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border 0.24s ease-in-out',
        // height: '100%',
    }

}));

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // marginTop: 16
    // backgroundColor: red,
    // padding: '20px',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: '120px',
    overflow: 'hidden',
    //   marginLeft: '50px',
    // paddingLeft: '20px',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};




const FileDropPreviews = forwardRef((props, ref) =>{

    const classes = useStyles();
    const [files, setFiles] = useState([]);
    // const [formParenSubmitted, setFormParenSubmitted] = useState(false);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    useImperativeHandle(
        ref,
        () => ({
            resetFilesState() {
                // setFiles([]);
                resetFiles()
            }
        }),
    );


function resetFiles (){
    // setFiles([]);
}

    function onDrop(acceptedFiles) {
        // const req = request.post('/upload')
        // acceptedFiles.forEach(file => {
        //   req.attach(file.name, file)
        // })
        // req.end(callback)
        // setFormParenSubmitted(false);
        props.parentCallback(acceptedFiles);
        acceptedFiles.forEach(file => {
            //  console.log(file);

        })
    }

    // function submitSuccess(){
    //     setFiles([]);
    // }
    // function showImage(imagePreview = false) {
    //     setFormParenSubmitted(true);
    //     return imagePreview;
    // }

// useEffect(() => {
//     if (props.imageHandling){
//         setFiles([]);
//     }
// }, [props.imageHandling]);



    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    // src={showImage(file.preview)}
                    style={img}
                    alt=''
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section className="container">

            <Grid item xs={12} sm={9} lg={10}>

                <div {...getRootProps({ className: classes.dropzone })}>
                    <input {...getInputProps()} onDrop={onDrop(files)} />
                    <p>Drag and drop one file here, or click to select files</p>
                </div>
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
                <aside style={thumbsContainer}>
                    {thumbs.length > 0 ? thumbs : <p style={{ padding: '20px', color: '#bdbdbd' }}>{'Accepted file'}</p>}
                </aside>
            </Grid>

        </section>
    );
});

export default FileDropPreviews;