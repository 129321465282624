import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import HeaderSingInOrRegister from '../utils/pageDetails/HeaderSingInOrRegister'


const useStyles = makeStyles((theme) => ({
    avatar: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.secondary.dark,
    },

    headCaption: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },

}));


const UserRegiLogPassHead = (props) => {

    const classes = useStyles();

    const [headCaption, setHeadCaption] = React.useState('Sign in');
    React.useEffect(() => {
        if (Object.keys(props).length >= 1) {
            if (props.headCaption !== undefined) {
                setHeadCaption(props.headCaption);
            }
        }
    }, [props]);


    return (
        <div>
            <HeaderSingInOrRegister />
            <Typography component="h1" variant="h5" className={classes.headCaption}>
                {headCaption}
            </Typography>
        </div>
    );
}

export default UserRegiLogPassHead;