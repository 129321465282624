import {useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fullPageYes } from '../../../actions'
import BuildWebsite from '../../utils/pageDetails/BuildWebsite'

const AccountPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fullPageYes())
        // return () => {
        //     cleanup
        // }
    }, [dispatch])
    return (
        <div>
            <BuildWebsite pageName={'My Account'} />
        </div>
    )
}

export default AccountPage