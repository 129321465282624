import { tokenObject, tokenVerify }  from '../components/utils/details';

export const fullPageYes = () => {
    return {
        type: 'FULL_PAGE_YES'
    }
}
export const fullPageNo = () => {
    return {
        type: 'FULL_PAGE_NO'
    }
}

export const userLogOut = () =>{
    return {
        type: 'LOG_OUT',
        payload: tokenObject
    }
}

export const userVerifyToken = (tokenVerify) =>{
    return {
        type: 'VERIFY_TOKEN',
        payload: tokenVerify
    }
}

export const userVerifyNo = () =>{
    return {
        type: 'VERIFY_EMPTY',
        payload: tokenVerify
    }
}

export const userLogIn = (tokenObject) =>{
    return {
        type: 'LOG_IN',
        payload: tokenObject
    }
}

export const healthTipCreatedYes = () => {
    return {
        type: 'HEALTH_TIP_CREATED_YES'
    }
} 

export const healthTipCreatedNo = () => {
    return {
        type: 'HEALTH_TIP_CREATED_NO'
    }
} 