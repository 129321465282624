import { useState } from 'react'
import { fade, makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import HomeIcon from '@material-ui/icons/Home'

import ShowChartIcon from '@material-ui/icons/ShowChart'
import FavoriteIcon from '@material-ui/icons/Favorite'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PersonIcon from '@material-ui/icons/Person'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import MoreIcon from '@material-ui/icons/MoreVert'

import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import Avatar from '@material-ui/core/Avatar'

import LogoIcon from '../../utils/icons/ LogoIcon'
import { appLinks } from '../../utils/appLinks'
import { darkAppBar } from '../../utils/themes/appTheme'
import { theme2 } from '../../utils/themes/stylesSignReg'
import { pageInfo } from '../../utils/details'
import { singInObject } from '../../utils/appLinks'

import { userLogOut, fullPageNo } from '../../../actions';
import { useDispatch } from 'react-redux';

import { Link as RouterLink } from 'react-router-dom'

import { useSelector } from 'react-redux'

import { yellow } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({

    root: {

    },

    grow: {
        backgroundColor: 'transparent',
    },
    grow2: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    menuButtonMobile: {
        marginRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        textTransform: 'capitalize',
        paddingLeft: theme.spacing(1),
    },
    appBar: {
        backgroundColor: theme.palette.appBar.main,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        // color: '#f6ff4c',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    yellow: {
        color: '#fff',
        backgroundColor: yellow[500],
        borderRadius: '60% 20% 20% 60% / 60% 30% 60% 30%',
        borderColor: '#fff',
        borderStyle: 'solid',
        borderWidth: '2px',
    },

    userName: {
        textTransform: 'capitalize',
        // fontSize: 12,
    },
    loginButton: {
        width: '100%',
        // label: {
        //     color: 'red',
        // }
    },
    label: {
        textTransform: 'capitalize',
        justifyContent: 'start',
      },
}))

export default function PrimaryAppBar() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null)
    const user = useSelector(state => state.token)
    // const [isUserLogin, setIsUserLogin] = useState(false)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
    // const [anchorElTipsMenu, setAnchorElTipsMenu] = useState(null)

    const darkAppBarTheme = createMuiTheme(darkAppBar)
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)



    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
        // handleTipsMenuClose()
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }


    const [anchorElLogOut, setAnchorElLogOut] = useState(null)
    const isMenuLogOutOpen = Boolean(anchorElLogOut)
    const handleClickLogOut = (event) => {
        setAnchorElLogOut(event.currentTarget)
    }

    const handleCloselogOut = () => {
        setAnchorElLogOut(null)
        handleMobileMenuClose()
    }

    const hendleSingOut = (e) => {
        handleMenuClose()
        dispatch(fullPageNo())
        window.localStorage.removeItem(String(singInObject.singInInfo));
        dispatch(userLogOut())
        // setTimeout(() => dispatch(userLogOut()), 100);
    }


    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem component={RouterLink} to={appLinks.profilePageLink} onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem component={RouterLink} to={appLinks.accountPageLink} onClick={handleMenuClose}>My account</MenuItem>
            <MenuItem component={RouterLink} to={appLinks.signInLink} onClick={hendleSingOut}>Sign out</MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem onClick={handleMenuClose} onClose={handleMenuClose}>
                <Button
                    component={RouterLink}
                    to={appLinks.homePageLink}
                    color="inherit"
                    className={classes.menuButtonMobile}
                    startIcon={<HomeIcon />}
                >Home</Button>
            </MenuItem>
            <MenuItem onClick={handleMenuClose} onClose={handleMenuClose}>
                <Tooltip title={user.singIn ? 'List of entered measurements and entry of a new blood pressure measurement' : 'You need to log in'}  >
                    <div>
                        <Button
                            component={RouterLink}
                            to={appLinks.bloodPressuresPageLink}
                            color="inherit"
                            className={classes.menuButtonMobile}
                            startIcon={<><ShowChartIcon /><FavoriteIcon style={{ fontSize: 12, margin: 'auto' }} /></>}
                            disabled={user.singIn ? false : true}
                        >BP</Button>
                    </div>
                </Tooltip>
            </MenuItem>
            { user.admin ?
                <MenuItem onClick={handleMenuClose} onClose={handleMenuClose}>
                    <Button
                        component={RouterLink}
                        to={appLinks.tipsPageLink}
                        color="inherit"
                        className={classes.menuButtonMobile}
                        startIcon={<RestaurantMenuIcon />}
                    >Tips</Button>
                </MenuItem> :
                null
            }



            {user.singIn ?
                <MenuItem onClick={handleProfileMenuOpen}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <p className={classes.userName}>Hi {user.username} </p>
                </MenuItem> :
                <MenuItem onClick={handleClickLogOut}>
                    <Button
                        aria-label="login or create new  user"
                        aria-controls="primary-search-logout-menu"
                        aria-haspopup="true"
                        startIcon={<PersonIcon />}
                        color="inherit">
                        ...</Button>
                </MenuItem>
            }
        </Menu>
    )


    const menuLogoutId = 'primary-search-logout-menu'
    const renderLogOutMenu = (
        <Menu
            anchorEl={anchorElLogOut}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuLogoutId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuLogOutOpen}
            onClose={handleCloselogOut}
        >
            <MenuItem onClick={handleCloselogOut}>
                <ThemeProvider theme={theme2}>
                    <Button
                        component={RouterLink}
                        to={appLinks.signInLink}
                        variant="contained"
                        size="small"
                        color="primary"
                        // className={classes.loginButton}
                        classes={{
                            root: classes.loginButton,
                            label: classes.label,
                        }}
                        startIcon={<ArrowForwardIcon />}
                    >Sing in</Button>
                </ThemeProvider>
            </MenuItem>
            <MenuItem onClick={handleCloselogOut}>
                <Button
                    component={RouterLink}
                    to={appLinks.registerPageLink}
                    size="small"
                    color="primary"
                    className={classes.textMenuTransformNone}
                >
                    New customer?  Start hire</Button>
            </MenuItem>

        </Menu >
    )


    const userLogoutDesktop = (
        <Button
            aria-label="login, create acount"
            aria-controls={menuLogoutId}
            aria-haspopup="true"
            endIcon={<ArrowDropDownIcon />}
            color="inherit"
            onClick={handleClickLogOut}
            startIcon={<PersonIcon />}
            className={classes.loginButtonDropDown}
        >
            ...
        </Button>
    )

    // const menuLoginId = 'primary-search-account-user-is-login'
    const userIsLoginDesktop = (

        <Button
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            color="inherit"
            onClick={handleProfileMenuOpen}
            startIcon={<AccountCircle />}
            endIcon={<ArrowDropDownIcon />}
            className={classes.textMenuTransformNone}
        >
            <span className={classes.userName}>Hi {user.username} </span>
        </Button>
    )

    return (
        <div className={classes.grow}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Button
                        component={RouterLink}
                        to={appLinks.homePageLink}
                        color="inherit"
                        className={classes.menuButton}
                        startIcon={<Avatar variant="rounded" className={classes.yellow}>  <LogoIcon style={{ height: 28, width: 'auto', }} /></Avatar>}>
                        <Typography className={classes.title} variant="h6" noWrap>
                            {pageInfo.name}
                        </Typography></Button>
                    {/* <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <Avatar variant="rounded" className={classes.yellow}>  <LogoIcon style={{ height: 28, width: 'auto' }} /></Avatar>
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                        {pageInfo.name}
                    </Typography> */}
                    <div className={classes.grow2} />
                    <div className={classes.sectionDesktop}>
                        <Button
                            component={RouterLink}
                            to={appLinks.homePageLink}
                            color="inherit"
                            className={classes.menuButton}
                            startIcon={<HomeIcon />}
                        >Home</Button>
                        <ThemeProvider theme={darkAppBarTheme}>
                            <Tooltip title={user.singIn ? 'List of entered measurements and entry of a new blood pressure measurement' : 'You need to log in'}  >
                                <div>
                                    <Button
                                        component={RouterLink}
                                        to={appLinks.bloodPressuresPageLink}
                                        color="inherit"
                                        className={classes.menuButton}
                                        startIcon={<><ShowChartIcon /><FavoriteIcon style={{ fontSize: 12, margin: 'auto' }} /></>}
                                        disabled={user.singIn ? false : true}
                                    >BP</Button>
                                </div>
                            </Tooltip>
                        </ThemeProvider>

                        <>
                            {user.admin ?
                                <Button
                                    component={RouterLink}
                                    to={appLinks.tipsPageLink}
                                    color="inherit"
                                    className={classes.menuButton}
                                    startIcon={<RestaurantMenuIcon />}
                                >Tips</Button> : null}
                        </>

                        <div style={{ minWidth: '115px', alignItems: 'end' }}>
                            {user.singIn ? userIsLoginDesktop : userLogoutDesktop}
                        </div>

                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            {renderLogOutMenu}
        </div>
    )
}

