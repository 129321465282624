import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { healthTipCreatedYes, healthTipCreatedNo } from '../../../actions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { Alert, AlertTitle } from '@material-ui/lab';
// import { dataLoaderPost2, links } from '../../utils/api/dataLoader';
import { apiEventService } from '../../utils/api/apiEventService';
import FileDropPreviews from '../../utils/FileDropPreviews';
import SingInDialog from '../../dialogs/SingInDialog';




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    uploadButton: {
        textTransform: 'none',
    },
    submit: {
        marginLeft: 'auto',
    },
    massageForUser: {
        height: '70px',
        marginBottom: '10px',
    },
    tokenSingInButton: {
        marginLeft: '20px',
    }
}));

const errorMessageTextFieldsInitial = {
    title: {
        message: ' ',
        status: false
    },
}

/**
 * 
 * @param {*} param0 
 */
const TipAlert = ({ message, severity = "success" }) => {

    return (
        <Alert severity={severity}>
            <AlertTitle>{message}</AlertTitle>
        </Alert>
    );
}

/**
 * 
 */
const TokenExpired = forwardRef((props, ref) => {

    const [open, setOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({

        tokenExpire() {
            handleClickOpen();
        }

    }));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <SingInDialog open={open} onClose={handleClose} message={props.message} />
        </>
    );
})

/**
 * minimum for implementation 
 * const formTipRef = useRef(); 
 * onClick={(e) => { formTipRef.current.handleSubmitFromParent(e) }}
 * and
 *  <FormTip ref={formTipRef} />
 */
const FormTip = forwardRef((props, ref) => {

    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorMessageTextFields, setErrorMessageTextFields] = React.useState(errorMessageTextFieldsInitial);

    const classes = useStyles();

    const tokenExpiredRef = useRef();

    const dispatch = useDispatch();

    const token = useSelector(state => state.token);
    // const isAdmin = useSelector(state => state.accessContent);
    const [fileData, setFileData] = useState([])

    const [values, setValues] = useState({
        title: '',
        content: '',
    });

    const handleSubmitToChild = useRef(null);
    // const [imageHandling, setImageHandling] = useState(false)

    const [respondData, setRespondData] = useState({
        data: '',
        headers: '',
        error: ''
    });


    let errorMessageText = {
        title: 'Title has a minimum of 3 characters.',

    }

    let handleErrorTitleHelper = (message, status) => {
        setErrorMessageTextFields((prevState) => ({
            ...prevState,
            title: {
                message: message,
                status: status
            },
        }))
    };

    let handleErrorTitle = e => {
        if (values.title.length < 3) {
            handleErrorTitleHelper(errorMessageText.title, true);
        } else {
            handleErrorTitleHelper(" ", false);
        }
    }

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "title") {
            if (errorMessageTextFields.title.status && e.target.value.length < 6) {
                handleErrorTitleHelper(errorMessageText.title, true);
            } else {
                handleErrorTitleHelper(" ", false);
            }
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            handleSubmitFromParent(event) {
                handleSubmit(event);
            }
        }),
    )

    function setFile(childData) {
        if (childData[0] !== undefined) {
            setFileData(Object.assign(childData[0]));
        }
    }

    function handleCallback(childData) {
        setFile(childData)
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setErrorMessage('');
        if (values.title === '') {


            handleErrorTitleHelper(errorMessageText.title, true);
            setRespondData({
                data: '',
                headers: '',
                error: ''
            });
            dispatch(healthTipCreatedNo());
        } else {

            var data = new FormData();
            data.append('title', values.title);
            data.append('content', values.content);
            data.append('fileTipImage', fileData);

            const addTip = await apiEventService.auth.addTip(
                {
                    data: data,
                    token: token.jwt,
                }
            )

            // console.log(addTip.status);
            if (addTip.status !== undefined && addTip.status !== null && addTip.status === 201) {
                setRespondData({
                    data: addTip,
                    headers: '',
                    error: ''
                });
                setValues({
                    title: '',
                    content: '',
                });
                setErrorMessage('');
                handleSubmitToChild.current.resetFilesState();
                dispatch(healthTipCreatedYes());
                handleErrorTitleHelper(' ', false);
            } else {
                setRespondData({
                    data: {
                        _embedded: {
                            tips: [],
                            page: [],
                        },
                        headers: addTip.headers,
                        error: addTip.error
                    }
                }

                );
                if (addTip.status === 401) {
                    tokenExpiredRef.current.tokenExpire();
                }

            }

        }
    }


    return (

        <div>

            <h3>Add Tips </h3>
            <div className={classes.massageForUser}>
                <>{respondData.data !== '' && respondData.data.status === 201 ? <TipAlert message={'New Tips is created'} /> : ''}</>
                <>{errorMessage === '' ? '' : <TipAlert message={errorMessage} severity={'error'} />}</>
                <>{respondData.data.error !== undefined && respondData.data.error !== null && respondData.data.error.code === 401 ?
                    <TokenExpired message={'Please sign in again - Your authorization has expired '} ref={tokenExpiredRef} /> : ''}</>
            </div>


            <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <TextField
                            required
                            id="productName"
                            name="title"
                            label="Tip name"
                            value={values.title}
                            onChange={handleChange}
                            fullWidth
                            autoComplete="product-name"
                            variant="outlined"
                            autoFocus
                            onBlur={handleErrorTitle}
                            helperText={errorMessageTextFields.title.message}
                            error={errorMessageTextFields.title.status}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-description-static"
                            label="Tip"
                            name="content"
                            value={values.content}
                            onChange={handleChange}
                            multiline
                            fullWidth
                            rows={4}
                            // defaultValue="Description"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <FileDropPreviews parentCallback={handleCallback} ref={handleSubmitToChild} />
                    </Grid>
                </Grid>
            </form>
        </div>
    )
})

export default FormTip;