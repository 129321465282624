import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import LogoImage from '../icons/LogoImage'

import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { appLinks } from '../appLinks'

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
         margin: 'auto',
    },
}))

const HeaderSingInOrRegister = () => {

    const classes = useStyles()
    return (
        <Tooltip title="go to home page" TransitionComponent={Zoom} TransitionProps={{ timeout: 600 }} aria-label="goToHomePage">
            <span>
                <RouterLink to={appLinks.homePageLink}>

                    <LogoImage className={classes.large} />

                </RouterLink>
            </span>

        </Tooltip>
    )
}

export default HeaderSingInOrRegister
