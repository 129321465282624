import { useEffect, useState, useRef, useReducer, useCallback } from 'react'
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import BloodPressureNoRowsOverlay from './BloodPressureNoRowsOverlay'
import BloodPressureLoaders from './BloodPressureLoaders'
import LineChart from '../../utils/chartjs/LineChart'
import Moment from 'moment';
import { Button } from '@material-ui/core';
import ExcelDownloadIcon2 from '../../utils/icons/ExcelDownloadIcon2'


const chartDataInitial = {
    //   labels: labels,
    datasets: [{
        label: 'Systolic ',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1,
        data: [
        ],
    },
    {
        label: 'Diastolic',
        backgroundColor: 'rgba(87, 209, 128, 0.5)',
        borderColor: 'rgb(87, 209, 128)',
        borderWidth: 1,
        data: [
        ],
    },
    {
        label: 'Pulse     ',
        backgroundColor: 'rgba(182, 17, 219, 0.5)',
        borderColor: 'rgb(182, 17, 219)',
        borderWidth: 1,
        data: [
        ],
    }
    ]
};

const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: '#' },
    { id: 'systolic', numeric: true, disablePadding: false, label: 'Systolic' },
    { id: 'diastolic', numeric: true, disablePadding: false, label: 'Diastolic' },
    { id: 'pulse', numeric: true, disablePadding: false, label: 'Pulse' },
    { id: 'measurementDate', numeric: false, disablePadding: true, label: 'Date' },
];

function descendingComparator(a, b, orderBy) {

    let bEl = b[orderBy]
    let aEl = a[orderBy]
    if (orderBy === 'measurementDate') {
        bEl = new Date(bEl)

        aEl = new Date(aEl)
    }

    if (bEl < aEl) {
        return -1;
    }
    if (bEl > aEl) {
        return 1;
    }
    return 0;

}

function getComparator(order, orderBy) {

    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {

        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >  { index !== 0 ?
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'desc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        : headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    // numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
};



const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        // marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



const useStyles = makeStyles({
    table: {
        minWidth: 650,
        // maxWidth: 850,
    },
});


// function usePrevious(value) {
//     const ref = useRef();
//     useEffect(() => {
//         ref.current = value;
//     });
//     return ref.current;
// }

const tableBodyRow = (row) => (
    <StyledTableRow key={row.id + row.measurementDate}>
        <StyledTableCell component="th" scope="row" align="right">
            {row.id}
        </StyledTableCell>
        <StyledTableCell align="right">{row.systolic}</StyledTableCell>
        <StyledTableCell align="right">{row.diastolic}</StyledTableCell>
        <StyledTableCell align="right">{row.pulse}</StyledTableCell>
        <StyledTableCell align="right">{row.measurementDate}</StyledTableCell>
    </StyledTableRow>
)


const rowsPerPageOptionsGenerate = (rowCountServer) => {
    const startArray = [10, 20, 50, 100, 200]
    let out = [5]
    startArray.map(element => (
        element < rowCountServer ? out.push(element) : ''
    ))
    if (out.length > 1) {
        out.push({ label: 'All', value: rowCountServer })
    }
    return out
}

const initialStateOrderAndOrderBy = {
    orderBy: 'measurementDate',
    order: 'desc'
}

const initialState = {
    pressures: [],
    page: {
        number: 0,
        size: 10,
        totalElements: 0,
    }
};

const BloodPressuresTableChart = ({ pressureData, newPageSet, downloadExcel }) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const chartLoading = useRef(true)
    const [chartData, setChartData] = useState(chartDataInitial);

    const [state, dispatch] = useReducer(reducer, initialState);
    const orderRef = useRef('desc')
    const orderByRef = useRef('measurementDate')
    const [stateOrderOrderBy, dispatchOrderOrderBy] = useReducer(reducerOrderOrderBy, initialStateOrderAndOrderBy);

    const stateOrderOrderByPrevious = useRef(stateOrderOrderBy)
    const emptyRows = state.page.size - state.pressures.length

    function reducerOrderOrderBy(state, action) {
        switch (action.type) {
            case 'order':
                return {
                    ...state,
                    order: orderRef.current
                }
            case 'orderBy':
                return {
                    ...state,
                    orderBy: orderByRef.current
                }
            case 'orderOrderBy':
                return {
                    order: orderRef.current,
                    orderBy: orderByRef.current
                }
            default:
                throw new Error();
        }
    }

    function reducer(state, action) {
        switch (action.type) {
            case 'get':
                if (pressureData.page === undefined || pressureData._embedded === undefined) {
                    return {
                        pressures: [],
                        page: initialState.page
                    }
                } else {
                    let id = pressureData.page.number * pressureData.page.size
                    const rows = pressureData._embedded.pressures.map((pr) => {

                        return ({
                            id: ++id,
                            systolic: pr.systolic,
                            diastolic: pr.diastolic,
                            pulse: pr.pulse,
                            measurementDate: Moment(pr.measurementDate).format('ddd MM/DD/YYYY, hh:mm a')
                        })
                    });
                    return {
                        pressures: rows,
                        page: pressureData.page

                    };
                }
            default:
                throw new Error();
        }
    }

    const handleRequestSort = (event, property) => {
        chartLoading.current = false
        const isAsc = stateOrderOrderBy.orderBy === property && stateOrderOrderBy.order === 'asc';

        orderRef.current = isAsc ? 'desc' : 'asc'
        orderByRef.current = property
        dispatchOrderOrderBy({ type: 'orderOrderBy' })
    };

    const handleChangePage = (event, newPage) => {
        //  size: 10, totalElements: 24, totalPages: 3, number: 1
        chartLoading.current = true
        newPageSet({
            page: newPage,
            order: stateOrderOrderBy.order,
            orderBy: stateOrderOrderBy.orderBy
        })
    };

    const handleChangeRowsPerPage = (event) => {
        chartLoading.current = true
        let newPageSetToServer = null
        if (parseInt(event.target.value) === -1) {
            newPageSetToServer = {
                rowsPerPage: state.page.totalElements,
            }
        } else {
            newPageSetToServer = {
                rowsPerPage: parseInt(event.target.value, 10),
            }
        }
        newPageSet({
            ...newPageSetToServer,
            page: 0,
            order: stateOrderOrderBy.order,
            orderBy: stateOrderOrderBy.orderBy
        })

    };

    const chartDataFill = useCallback((rows) => {
        let systolic = []
        let diastolic = []
        let pulse = []

        function pushData(row) {
            systolic.push({ x: new Date(row.measurementDate), y: row.systolic })
            diastolic.push({ x: new Date(row.measurementDate), y: row.diastolic })
            pulse.push({ x: new Date(row.measurementDate), y: row.pulse })
        }

        stableSort(rows, getComparator(stateOrderOrderBy.order, 'measurementDate'))
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .slice(0, state.page.size)
            .map((row, index) => (
                pushData(row)
            ))

        setChartData(prevState => ({

            datasets: [
                {
                    ...prevState.datasets[0],
                    data: systolic,
                },
                {
                    ...prevState.datasets[1],
                    data: diastolic,
                },
                {
                    ...prevState.datasets[2],
                    data: pulse,
                },
            ],
        }))


    }, [stateOrderOrderBy.order, state.page.size])


    useEffect(() => {
        //    if (pressureData._embedded !== undefined) {
        dispatch({ type: 'get' })
        // statePrevious.current = state

        setLoading(false)
        //    }

        // setLoading(false)
        return () => {
            setLoading(true)
        };
    }, [pressureData])


    useEffect(() => {
        if (chartLoading.current) {
            chartDataFill(state.pressures)
        }



        return () => {

        }
    }, [state.pressures, chartDataFill])

    return (
        <div>
            {/* <div style={{ marginTop: 25, marginBottom: 25}}> */}
            <div style={{ Width: 300, whiteSpace: 'nowrap' }}>
                <Box component="div" overflow="visible">
                    <LineChart data={chartData} />
                </Box>
            </div>

            {/* </div> */}



            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="customized table">

                    <EnhancedTableHead
                        classes={classes}
                        // numSelected={selected.length}
                        order={stateOrderOrderBy.order}
                        orderBy={stateOrderOrderBy.orderBy}
                        // onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                    // rowCount={rows.length}
                    />
                    <TableBody>
                        {
                            loading ?

                                <TableRow style={{ height: 320 + 13.04 }}>
                                    <TableCell colSpan={6}>
                                        <BloodPressureLoaders />
                                    </TableCell>
                                </TableRow>
                                :
                                state.page.size >= state.page.totalElements
                                    || stateOrderOrderBy.order !== stateOrderOrderByPrevious.current.order
                                    || stateOrderOrderBy.orderBy !== stateOrderOrderByPrevious.current.orderBy

                                    ?
                                    stableSort(state.pressures, getComparator(stateOrderOrderBy.order, stateOrderOrderBy.orderBy))
                                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .slice(0, state.page.size)
                                        .map((row, index) => (
                                            tableBodyRow(row)
                                        ))
                                    :
                                    state.pressures
                                        .map((row) => (
                                            tableBodyRow(row)
                                        ))
                        }{loading ? null :
                            state.page.size > 0 && (
                                <TableRow style={{ height: 32 * emptyRows + 13.04 }}>
                                    <TableCell colSpan={6}>
                                        {state.page.totalElements === 0 ? <BloodPressureNoRowsOverlay /> : ''}
                                    </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<ExcelDownloadIcon2 />}
                                    style={{ textTransform: 'none' }}
                                    onClick={downloadExcel}
                                >Download as excel file</Button>
                            </TableCell>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptionsGenerate(state.page.totalElements)}
                                colSpan={4}
                                count={state.page.totalElements}
                                rowsPerPage={state.page.size}
                                page={state.page.number}
                                SelectProps={{
                                    // renderValue: value => (value === rowCountServer ? 'All' : value),
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: false,
                                }}
                                // paginationMode="server"
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}

export default BloodPressuresTableChart
