import { makeStyles, createMuiTheme } from '@material-ui/core/styles';


export const stylesSignReg = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const theme2 = createMuiTheme({
    palette: {
        // primary: orange,
        // secondary: yellow,
        primary: {
            light: '#757ce8',
            main: '#ff9100',
            dark: '#ffa733',
            contrastText: '#fff',
        },
        type: "dark",
    },
});