
import React, { useState, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Grid from '@material-ui/core/Grid';


import DateFnsUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';

import { apiEventService } from '../../utils/api/apiEventService';
import { pageInfo } from '../../utils/details'
import BloodPressuresTableChart from './BloodPressuresTableChart'
import SingInDialog from '../../dialogs/SingInDialog'

const useStyles = makeStyles((theme) => ({
  inputBlock: {
    height: "56px",
    marginLeft: 'auto',
    marginBottom: 'auto',
    marginTop: theme.spacing(2),
    padding: 0,
    paddingRight: theme.spacing(3),
  },
  savaButton: {
    height: "56px",
  },

  addBMessage: {
    paddingTop: theme.spacing(2),
  },
  addedBMessage: {
    color: theme.palette.success.main,
    paddingTop: theme.spacing(2),
  },
  notAddedMessage: {
    color: theme.palette.error.main,
    paddingTop: theme.spacing(2),
  }
}));


const CssTextField = withStyles({
  root: {
  },
})(TextField);



const TokenExpired = forwardRef((props, ref) => {

  const [open, setOpen] = React.useState(false);
  const [firstOpen, setFirstOpen] = React.useState(true);
  useImperativeHandle(ref, () => ({

    tokenExpire() {
      handleClickOpen();
    }

  }));

  useEffect(() => {
    if (props.open && firstOpen) {
      setOpen(true);
      setFirstOpen(false)
    }
    return () => {
      // setOpen(false)

    }
  }, [props.open, firstOpen])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SingInDialog open={open} onClose={handleClose} message={props.message} />
    </>
  );
})


const heartSymbolHtml = String.fromCharCode(10084);

/**
 * 
 * @param {*} props 
 * @returns 
 */
const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...others } = props;


  /**
   * 
   * @param {*} val 
   * @returns 
   */
  function sysDisPulse2(val) {
    let vls = 3
    let vld = 6
    let vlp = 9
    let systolic = ''
    let diastolic = ''
    let puls = ''
    if (val[0] === '0') {

      systolic = ''
    }
    else if (val[0] > 2) {
      vls = 2
      systolic = val.substring(0, vls)
    } else {
      vls = 3
      systolic = val.substring(0, vls)
    }
    if (val.length > vls) {

      if (val[vls] > 1) {
        vld = vls + 2

      } else {
        vld = vls + 3
      }
      diastolic = val.substring(vls, vld)
      if (parseInt(systolic) <= parseInt(diastolic)) {
        diastolic = val.substring(vls, vld - 1)
      }

    }

    if (val.length > vld) {
      if (val[vld] > 1) {
        vlp = vld + 2
      } else {
        vlp = vld + 3
      }
      puls = val.substring(vld, vlp)
    }

    return systolic + (diastolic.length ? '/' + diastolic : '') + (puls.length ? heartSymbolHtml + puls : '');
  }

  return (
    <NumberFormat
      {...others}
      getInputRef={inputRef}
      onValueChange={(values) => {

        onChange({

          target: {
            name: props.name,
            value: values.value,
            formattedValue: values.formattedValue,
          },
        });
      }}
      format={sysDisPulse2}
      placeholder={'sys/dis' + heartSymbolHtml + 'pulse'}
    />
  );
}

const loadServerRows = (token) => {
  return apiEventService.getData.getPressureWithUserToken(token)
}

const initialDataToServer = {
  page: 0,
  rowsPerPage: 10,
  orderBy: 'measurementDate',
  order: 'DESC',
  token: ''

}

const openDialogInitial = {
  title: '',
  content: '',
  open: false,
}

/**
 * 
 */
const BloodPressures = forwardRef((props, ref) => {

  const token = useSelector(state => state.token);

  const classes = useStyles();


  const tokenExpiredRef = useRef();
  const oldToken = useRef('fg');
  // const dataLoud = useRef('fg');

  const [selectedDate, setSelectedDate] = useState(new Date());

  // const [dataToServer, setDataToServer] = useState(
  //   dataToServerIn
  // )
  const dataToServerRef = useRef(initialDataToServer)


  const [pressureData, setPressureData] = useState([]);

  const [errorTextField, setErrorTextField] = useState(false)

  const [respondData, setRespondData] = useState({
    data: '',
    headers: '',
    error: ''
  });

  const [error401, setError401] = useState(false)
  // const [errorHandler, setErrorHandler] = useState({
  //   data: "",
  //   error: "",
  //   headers: "",
  //   status: null
  // })
  const [openDialog, setOpenDialog] = React.useState({ openDialogInitial });

  const handleClickDialogOpen = (title = '', content = '') => {
    setOpenDialog({
      title,
      content,
      open: true,
    });
  };

  const handleDialogClose = () => {
    setOpenDialog({ openDialogInitial });
  };




  useImperativeHandle(
    ref,
    () => ({
      handleSubmitFromParent(event) {
        handleSubmit(event);
      }
    }),
  )

  const getLoadData = useCallback(async () => {
    dataToServerRef.current = { ...dataToServerRef.current, token: token.jwt }
    if (token.singIn) {
      setError401(false)
      const pressures = await loadServerRows(dataToServerRef.current);
      // setPressureData(pressures.data._embedded.pressures)
      if (pressures.data._embedded === undefined) {
        setPressureData(pressures.data)
        if (pressures.status === 401) {
          setError401(true)
        }
      } else {
        if (pressures.data._embedded !== undefined) {
          setPressureData(pressures.data)
        } else {
          setPressureData([pressures.data])
        }
      }
    }


  }, [token])

  useEffect(() => {
    getLoadData();
    return () => {
      setPressureData([])

    }
  }, [getLoadData])


  const addTitleBMessage = useCallback((type = 'default') => {
    if (type === 'default') {
      return (
        <h4 className={classes.addBMessage}>Add a new BP record </h4>
      )
    } else if (type === 'success') {
      return (<h4 className={classes.addedBMessage}>Record successfully added</h4>)
    } else if (type === 'error') {
      return (<h4 className={classes.notAddedMessage}>Error - record not added</h4>)
    }
    return (
      <h4 className={classes.addBMessage}>Add a new BP record </h4>
    )
  }, [classes.addBMessage, classes.addedBMessage, classes.notAddedMessage])

  function handlePage(pageIn) {
    // page: 0,
    // rowsPerPage: 10,
    // orderBy: 'measurementDate',
    // order: 'DESC',
    // token: 
    let pageInNew = null
    if (pageIn.page !== undefined && pageIn.page !== dataToServerRef.current.page)
      pageInNew = { page: pageIn.page }
    if (pageIn.rowsPerPage !== undefined && pageIn.rowsPerPage !== dataToServerRef.current.rowsPerPage)
      pageInNew = { ...pageInNew, rowsPerPage: pageIn.rowsPerPage }
    if (pageIn.orderBy !== undefined && pageIn.orderBy !== dataToServerRef.current.orderBy)
      pageInNew = { ...pageInNew, orderBy: pageIn.orderBy }
    if (pageIn.order !== undefined && pageIn.order !== dataToServerRef.current.order)
      pageInNew = { ...pageInNew, order: pageIn.order }

    if (pageInNew !== null) {
      dataToServerRef.current = { ...dataToServerRef.current, ...pageInNew }
      getLoadData()
    }

  }

  function downloadExcel(e) {
    e.preventDefault()
    apiEventService.getData.downloadExcel({ token: token.jwt })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(pageInfo.download, pageInfo.nameExcelFile);
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
      
        handleClickDialogOpen(
          `Error - file ${pageInfo.nameExcelFile}`,
          `The ${pageInfo.nameExcelFile} file cannot be downloaded.`
        )
      });

  }

  const [messageAddBP, setMessageAddBP] = React.useState(addTitleBMessage())
  const handleDateChange = (date) => {
    setMessageAddBP(addTitleBMessage())
    setSelectedDate(date);
    // if (errorTextField)
    // setErrorTextField(false)

  };

  const handleDateOnBlur = (e) => {
    e.preventDefault();
    setMessageAddBP(addTitleBMessage());
    if (errorTextField)
      setErrorTextField(false)
  }

  const [values, setValues] = React.useState({
    numberformat: '',
    formattedValue: '',
  });

  function minDateCal(date = new Date()) {
    return new Date(date.setMonth(date.getMonth() - 3));
  }

  const addTitleBMessageCallBack = useCallback((trigger) => {
    addTitleBMessage(trigger);
  }, [addTitleBMessage])

  const saveBloodPressure = useCallback(() => {
    let splitFormattedValue = []
    if (values.formattedValue.search('/') > 0 && values.formattedValue.search(heartSymbolHtml) > 1) {
      splitFormattedValue = values.formattedValue.replace(heartSymbolHtml, '/').split('/')
      setError401(false)
      apiEventService.auth.addBPressure(
        {
          data: {
            "systolic": parseInt(splitFormattedValue[0]),
            "diastolic": parseInt(splitFormattedValue[1]),
            "pulse": parseInt(splitFormattedValue[2]),
            "measurementDate": selectedDate.toJSON()
          },
          token: token.jwt,
        }
      ).then(respond => {

        setValues({
          numberformat: '',
          formattedValue: '',
        });
        // setMessageAddBP(addTitleBMessage('success'));
        setMessageAddBP(addTitleBMessageCallBack('success'))
        getLoadData();
        setRespondData({
          data: respond,
          headers: '',
          error: ''
        });


      }).catch(error => {
        if (error.response) {
          setRespondData({
            data: '',
            headers: error.response.headers,
            error: error.response.data
          });
          if (error.response.data.code === 401) {
            // tokenExpiredRef.current.tokenExpire()
            setError401(true)
            oldToken.current = token.jwt
          }

        } else if (error.request) {
          setRespondData({
            data: '',
            headers: '',
            error: 'try later'
          });
        } else {
          setRespondData({
            data: '',
            headers: '',
            error: error.message
          });
        }
        // setMessageAddBP(addTitleBMessage('error'));
        setMessageAddBP(addTitleBMessageCallBack('error'))
      })
    }
  }, [addTitleBMessageCallBack, getLoadData, selectedDate, token.jwt, values.formattedValue])


  const handleSubmit = (e) => {
    e.preventDefault();

    saveBloodPressure()
    if (respondData.data === '' && respondData.error === '' && respondData.headers === '') {
      setErrorTextField(true)
    } else {
      setErrorTextField(false)
    }

  }

  // const saveBloodPressureCallBack = useCallback(() =>{
  //   saveBloodPressure()
  // }, [saveBloodPressure]) 

  useEffect(() => {
    const old = oldToken.current
    if (error401 && old.localeCompare(token.jwt) !== 0) {
      saveBloodPressure()
    }
    return () => {
      // setError401(false)
    }

  }, [token.jwt, saveBloodPressure, error401])

  const handleChange = (event) => {
    setMessageAddBP(addTitleBMessage());
    setValues((prevState) => ({
      ...prevState,
      numberformat: event.target.value,
      formattedValue: event.target.formattedValue,
    }))
  };


  const pressure = () => {
    return (
      <div>
        <BloodPressuresTableChart pressureData={pressureData} newPageSet={handlePage} downloadExcel={downloadExcel} />
      </div>
    )
  }
  return (

    <>
      <>{error401 ?
        <TokenExpired message={'Please sign in again - Your authorization has expired '} open={error401} ref={tokenExpiredRef} /> : <> </>}</>
      {/* <>{respondData.error !== undefined && respondData.error.code === 401 ?
        <TokenExpired message={'Please sign in again - Your authorization has expired '} ref={tokenExpiredRef} /> : 'dfdfdfdfdfdf'}</> */}
      <h3>Blood Pressure</h3>
      <form onSubmit={handleSubmit} noValidate>

        <Grid container justify="space-around">
          <Grid item xs={12} md={3}>
            {messageAddBP}
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                className={classes.inputBlock}
                margin="normal"
                id="date-picker-time-dialog"
                label="date and time of measurement"
                variant="outlined"
                inputVariant="outlined"
                fullWidth
                maxDate={new Date()}
                minDate={minDateCal()}
                value={selectedDate}
                name={'measurementDate'}
                onChange={handleDateChange}
                onBlur={handleDateOnBlur}
                onFocus={handleDateOnBlur}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <CssTextField
              className={classes.inputBlock}
              error={errorTextField}
              helperText={errorTextField ? 'Incorrect entry.' : ' '}
              margin="normal"
              label={'sys/dis' + heartSymbolHtml + 'pulse'}
              variant="outlined"
              value={values.formattedValue}
              fullWidth
              // type="password"
              // value={'dfdf'}

              name="numberformat"
              id="formatted-numberformat-input"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={handleChange}
              onFocus={handleDateOnBlur}
            />
          </Grid>

          <Grid item xs={7} md={3} className={classes.inputBlock}>
            <Button
              className={classes.savaButton}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"

            >Save</Button>
          </Grid>
          <Grid item xs={12}>
            {pressure()}
          </Grid>
        </Grid>
      </form>

      <Dialog
        open={openDialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ openDialog.title }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          { openDialog.content }
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>


    </>

  );
})

export default BloodPressures
