export const tokenObject = {
  jwt: '',
  username: '',
  admin: false,
  singIn: false
}

// export const singInObject = {
//   singInInfo: 'singInInfo',
//   accessContent: 'access-content'
// } 
export const tokenVerify = {
  jwt: '',
  email: '',
}

export const pageInfo = {
  name: 'Health',
  description: 'Your Health',
  download: 'download',
  nameExcelFile: 'customers.xlsx',
}

/**
 * 
 * @param {*} email 
 * @returns 
 */
export const maskEmail = (email = '') => {
  const [name, domain] = email.split('@');
  const { length: len } = name;
  const maskedName = name[0] + '...' + name[len - 1];
  const maskedEmail = maskedName + '@' + domain;
  return maskedEmail;
};


/**
 * 
 * @param {*} email 
 * @returns 
 */
export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toString().toLowerCase());
}

export const errorDataEmpty = {
  data: '',
  status: '',
  error: {
    code: '',
    date: '',
    errors: null,
    message: ''
  }
}

export const comparesStrings = (string1='', string2='') =>{
  string1 = string1.toString()
  string2 = string2.toString()

 return string1.localeCompare(string2) === 0 ? true : false 

}