import pink from '@material-ui/core/colors/pink';
import teal from '@material-ui/core/colors/teal';

export const userTheme = {
  palette: {
    primary: {
      main: "#0d47a1",
      light: '#63ccff',
      dark: '#006db3',
    },
    secondary: {
      main: '#e53935',
      light: '#ff6f60',
      dark: '#ab000d',
    },
    background: {
      default: '#eef5f9',
    },
    footer: {
      main: '#e1f5fe'
    },
    appBar: {      
      main: '#007db4',
      light: '#56ace6',
      dark: '#005184',
    },
    appFooter: {
      main: '#dcedf7',
      light: '#ffffff',
      dark: '#aabbc4',
    },
    text: {
      // primary: '#e1f5fe',
       disabled: '#e1f5fe',
    },

  },
  // spacing: 4,
};


export const adminTheme = {
  palette: {

    common: {
      black: '#000',
      white: '#fff'
    },
    text: {
      disabled: 'red',
    },
    type: 'light',
    primary:
    {
      main: teal[400],
    },
    secondary: {
      main: pink[500],
    },
    background: {
      default: '#eef5f9',
    },
    // footer: {
    //   main: '#ede7f6'
    // },
    appBar: {
      main: '#78002e'
    },
    appFooter: {
      main: '#dcedf7',
      light: '#ffffff',
      dark: '#aabbc4',
    },
  },
  // spacing: 4,
};

export const darkAppBar = {
  palette:{
      // type: 'dark',
      text:{
          disabled: '#c9e3ff'
      },
      action: {
          disabled: 'rgba(255, 255, 255, 0.5)',
      }
  },
 
}

