
import Avatar from '@material-ui/core/Avatar'


// const useStyles = makeStyles((theme) => ({

// }))
const LogoImage = (props) => {
    return (
        <Avatar src={process.env.PUBLIC_URL + "/redAppel2.svg"} alt="Logo" className={props.className} />
    )
}



export default LogoImage
