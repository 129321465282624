/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 * sowing regular page with appBar and footer or SingIn with out appBar and footer
 */
const fullPageReducer = (state = true, action) => {
    switch(action.type){
        case 'FULL_PAGE_YES':
            state = true;
            return state;
        case 'FULL_PAGE_NO':
            state = false;
            return state;
        default:
            return state;
    }
};
export default fullPageReducer;