import { useEffect, useState, useRef, useReducer } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link as RouterLink  } from "react-router-dom";
import { appLinks } from "../../utils/appLinks";
import { fullPageNo } from "../../../actions";

import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"

import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Copyright from '../../utils/Copyright'
import UserRegiLogPassHead from '../../utils/UserRegiLogPassTitle'
import { stylesSignReg } from '../../utils/themes/stylesSignReg'
import { apiEventService } from '../../utils/api/apiEventService'
import { errorDataEmpty, validateEmail, comparesStrings } from '../../utils/details'
import EmailForm from './EmailForm'
import ResetPasswordForm from './ResetPasswordForm'
import { reducer, initialState } from './resetPasswordReducer'
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}))


function getSteps() {
    return ['Send email with token', 'Reset Password', 'Create an ad'];
}

function getButtonLabel(step) {
    switch (step) {
        case 0:
            return `Send email`
        case 1:
            return `Reset Password`
        case 2:
            return `next 2`
        default:
            return `Unknown`
    }
}

function GetStepContent(props) {
    const { errorData, state } = props
    const eventHandler = (data) => {
        props.parentCallback(data);
    };
    switch (props.step) {
        case 0:
            return (<EmailForm onChange={eventHandler} errorData={errorData} state={state} />);
        case 1:
            return (<ResetPasswordForm onChange={eventHandler} errorData={errorData} state={state} />);
        case 2:
            return `You have successfully reset the password.`;
        default:
            return 'Unknown step';
    }
}


const ResetPasswordPage = () => {
    const classesSingin = stylesSignReg();
    const classes = useStyles();
    const dispatch = useDispatch()
    const emailRef = useRef(0);
    const stage2SubmitRef = useRef(false);
    const [state, dispatchR] = useReducer(reducer, initialState);

    const userIsLogin = useSelector((state) => state.token);

    useEffect(() => {
        dispatch(fullPageNo())
        // return () => {
        //     cleanup
        // }
    }, [dispatch])

    const [activeStep, setActiveStep] = useState(0);
    const [errorData, setErrorData] = useState(errorDataEmpty)
    const steps = getSteps();


    const callbackFunction = (childData) => {

        emailRef.current = childData;
        setErrorData(errorDataEmpty)
        if (activeStep === 0) {
            dispatchR({ type: 'email', payload: emailRef.current })
        }
        else if (activeStep === 1) {
            dispatchR({ type: 'resetPassword', token: childData.token, password: childData.password, reEnterPassword: childData.reEnterPassword })
            if (stage2SubmitRef.current)
                errorStage2(childData, dispatchR)
        }
        // setInvalidCode(false)
    };

    const handleNext = async (e) => {

        e.preventDefault();
        let respond = ''
        if (activeStep === 0) {
            // const data = { email: emailRef.current, text:'text' }
            if (validateEmail(state.email)) {
                let data = new FormData();

                data.append('email', state.email)

                respond = await apiEventService.auth.sendEmailR({
                    data: data
                })
                if (respond.status === 200 || respond.status === 201) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    setErrorData(respond)
                }

            } else {
                let notValidEmail = errorDataEmpty
                let email = emailRef.current
                //   let message = ''
                const message = email.length < 1 ? 'Sorry, this field cannot be left blank' : 'Sorry, please enter a valid email address'
                notValidEmail = {
                    ...notValidEmail, status: 4000, error: {
                        ...notValidEmail.error,
                        code: 4000,
                        message: message,
                    }
                }

                setErrorData(notValidEmail)
            }

        } else if (activeStep === 1) {

            // setActiveStep((prevActiveStep) => prevActiveStep + 1);

            if (comparesStrings(state.password, state.reEnterPassword) && state.token.length === 36 && state.password.length > 7) {

                let data = new FormData();

                data.append('token', state.token)
                data.append('password', state.password)

                respond = await apiEventService.auth.resetPassword({
                    data: data
                })
                if (respond.status === 200 || respond.status === 201) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    setErrorData(respond)
                }

            } else {
                stage2SubmitRef.current = true
                errorStage2(state, dispatchR)
            }


        }

    };

    const handleBack = () => {

        setErrorData(errorDataEmpty)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

if(userIsLogin!== undefined && userIsLogin.singIn !== undefined && userIsLogin.singIn){
    return <Redirect to={appLinks.homePageLink} />
}

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classesSingin.paper}>
                <UserRegiLogPassHead headCaption="Reset Password" />
            </div>
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography component={"span"} variant={"body2"}>
                                    <GetStepContent
                                        step={index}
                                        parentCallback={callbackFunction}
                                        errorData={errorData}
                                        state={state}
                                    />
                                </Typography>
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0 || activeStep === steps.length - 1}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >Back</Button>
                                        {activeStep === steps.length - 1 ?
                                            <Button
                                                component={RouterLink}
                                                to={appLinks.signInLink}
                                                className={classes.button}
                                                startIcon={<AccountCircleIcon />}
                                                variant="contained"
                                                color="primary"
                                            >
                                                Sign in{" "}
                                            </Button> :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                className={classes.button}
                                               
                                            >
                                                {getButtonLabel(index)}
                                            </Button>

                                        }
                                        {/* <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                            startIcon={activeStep === steps.length - 1 ? <AccountCircleIcon />: ' '}
                                        >
                                            {activeStep === steps.length - 1 ? 'Sing in' : getButtonLabel(index)}
                                        </Button> */}
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
          </Button>
                    </Paper>
                )}
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    )
}

export default ResetPasswordPage

function errorStage2(state, dispatchR) {
    let errorTokenValue = ''
    let errorTokenStatus = false
    let errorPasswordValue = ''
    let errorPasswordStatus = false
    let errorReEnterPasswordValue = ''
    let errorReEnterPasswordStatus = false
    if (state.token.length !== 36) {
        errorTokenValue = 'Not valid Token'
        errorTokenStatus = true
    }
    if (state.password.length < 8) {
        errorPasswordValue = 'There is a minimum of 8 characters for Password'
        errorPasswordStatus = true
    }
    if (!comparesStrings(state.password, state.reEnterPassword)) {
        errorReEnterPasswordValue = 'Password and re-entered password are not identical'
        errorReEnterPasswordStatus = true
    }

    dispatchR({
        type: 'errorStage2',
        errorTokenValue: errorTokenValue,
        errorTokenStatus: errorTokenStatus,
        errorPasswordValue: errorPasswordValue,
        errorPasswordStatus: errorPasswordStatus,
        errorReEnterPasswordValue: errorReEnterPasswordValue,
        errorReEnterPasswordStatus: errorReEnterPasswordStatus
    })
}
