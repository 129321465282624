import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { healthTipCreatedNo, fullPageYes } from '../../../actions';
import { apiEventService } from '../../utils/api/apiEventService';
import AdminListTips from './AdminListTips';
import { Alert, AlertTitle } from '@material-ui/lab';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TipDialogs from './TipDialogs';

const useStyles = makeStyles((theme) => ({
    circularProgressRoot: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        minHeight: '60vh',
    },
    circularProgress: {
        margin: 'auto',
    },
    tipDialogs: {
        marginTop: 25,
        marginBottom: 25
    }
}))

const TipsPage = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [respondData, setRespondData] = useState({
        data: {
            _embedded: {
                tips: [],
                page: [],
            }
        },
        headers: '',
        error: ''
    });
    const token = useSelector(state => state.token);

    const healthTipCreated = useSelector(state => state.healthTipCreated);

    const [loadingData, setLoadingData] = useState(true)

    const getLoadData = useCallback(async () => {
        const getTips = await apiEventService.getData.getTips({
            token: token.jwt,
        })

        if (getTips.status !== undefined && getTips.status !== null && getTips.status === 200) {
            setRespondData({
                data: getTips.data,
                headers: '',
                error: ''
            });
        } else {
            setRespondData({
                data: {
                    _embedded: {
                        tips: [],
                        page: [],
                    },
                    headers: getTips.headers,
                    error: getTips.data
                }
            });
        }
        setLoadingData(false);

    }, [token.jwt, setLoadingData])



    useEffect(() => {

        getLoadData()
    }, [getLoadData])


    useEffect(() => {
        dispatch(fullPageYes())
        // return () => {
        //     cleanup
        // }
    }, [dispatch])


    useEffect(() => {

        const reloadAfterNewTip = () => {
            if (healthTipCreated)
                getLoadData()
            dispatch(healthTipCreatedNo())
        }

        reloadAfterNewTip();
        // return () => {
        //     dispatch(healthTipCreatedNo());
        // }
    }, [dispatch, getLoadData, healthTipCreated])



    function updateData(e) {
        e.preventDefault()

        setLoadingData(true)
        getLoadData()
    }


    if (respondData.data._embedded !== undefined &&
        respondData.data._embedded !== null &&
        respondData.data._embedded.tips.length > 0) {


        return (
            <div>
                <AdminListTips tips={respondData.data._embedded.tips} page={respondData.data.page} />
            </div>
        )
    } else if (respondData.error !== '') {

        return (
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
        This is an error alert — <strong>check network connection!</strong>
            </Alert>
        )
    } else if (loadingData) {

        return (
            <div className={classes.circularProgressRoot}>

                <CircularProgress className={classes.circularProgress} disableShrink />

            </div>
        )
    }
    else {

        return (
            <>
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
Info - <strong>no published health tips</strong>
                </Alert>
                <div>
                    <TipDialogs updateDataAction={updateData} />
                </div>

            </>
        )
    }

}

export default TipsPage
