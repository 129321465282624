import { useEffect, useState, useCallback } from 'react'

import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import { Redirect, Link as RouterLink } from 'react-router-dom'
import { appLinks, singInObject } from '../../../utils/appLinks'
import SingOut from '../../../utils/pageDetails/SingOut'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Alert from '@material-ui/lab/Alert';


import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Copyright from '../../../utils/Copyright'

import UserRegiLogPassHead from '../../../utils/UserRegiLogPassTitle'

import { useDispatch, useSelector } from 'react-redux';
import { fullPageNo, userLogIn, fullPageYes } from '../../../../actions'
import { stylesSignReg } from '../../../utils/themes/stylesSignReg';

import { apiEventService } from '../../../utils/api/apiEventService'

// const useStyles = makeStyles((theme) => ({
//   // paper: {
//   //   marginTop: theme.spacing(8),
//   //   display: 'flex',
//   //   flexDirection: 'column',
//   //   alignItems: 'center',
//   // },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
//   large: {
//     width: theme.spacing(8),
//     height: theme.spacing(8),
//   },
//   warring: {
//     backgroundColor: 'transparent',
//     color: theme.palette.secondary.dark,
//     fontSize: 12

//   },
// }));

const buttonFont = createMuiTheme({
  typography: {
    fontFamily: [
      'Cormorant',
      'serif',
    ].join(','),
  },
});

const formInitial = {
  username: '',
  password: '',
  remember: false

}

const errorMessageTextFieldsInitial = {
  username: {
    message: ' ',
    status: false
  },
  password: {
    message: ' ',
    status: false
  },
}



export default function SignInPage() {
  const classes = stylesSignReg();
  const dispatch = useDispatch();
  const token = useSelector(state => state.token);

  const [form, setForm] = useState(formInitial);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageTextFields, setErrorMessageTextFields] = useState(errorMessageTextFieldsInitial);
  const [loggedInSuccessAndRedirect, setLoggedInSuccessAndRedirect] = useState(false);
  const [usernameEmail, setUsernameEmail] = useState({
    maskEmail: '',
    set: false,
  });

  const userMail = useCallback(
    (maskEmail) => {
      setUsernameEmail({
        maskEmail: maskEmail,
        set: true
      })
    },
    [],
  )

  useEffect(() => {
    if (token === null || token.jwt === '') {
      dispatch(fullPageNo())
    } else {
      dispatch(fullPageYes())
    }
    return () => {
      dispatch(fullPageNo())
    }
  }, [dispatch, token])

  let errorMessageText = {
    username: 'Username has a minimum of 6 characters.',
    password: 'Password has a minimum of 8 characters. '
  }
  let handleErrorPasswordHelper = (message, status) => {
    setErrorMessageTextFields((prevState) => ({
      ...prevState,
      password: {
        message: message,
        status: status
      },
    }))
  };

  let handleErrorUsernameHelper = (message, status) => {
    setErrorMessageTextFields((prevState) => ({
      ...prevState,
      username: {
        message: message,
        status: status
      },
    }))
  };

  let handleErrorUsername = e => {
    if (form.username.length < 6) {
      handleErrorUsernameHelper(errorMessageText.username, true);
    } else {
      handleErrorUsernameHelper(" ", false);
    }
  }

  let handleErrorPassword = e => {
    if (form.password.length < 8) {
      handleErrorPasswordHelper(errorMessageText.password, true);
    } else {
      handleErrorPasswordHelper(' ', false);
    }
  }

  let handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
    if (errorMessage !== '') {
      setErrorMessage('')
    }
    if (e.target.name === "username") {
      if (errorMessageTextFields.username.status && e.target.value.length < 6) {
        handleErrorUsernameHelper(errorMessageText.username, true);
      } else {
        handleErrorUsernameHelper(" ", false);
      }
    } else if (e.target.name === "password") {
      if (errorMessageTextFields.password.status && e.target.value.length < 8) {
        handleErrorPasswordHelper(errorMessageText.password, true);
      } else {
        handleErrorPasswordHelper(" ", false);
      }
    }
  }
  const handleChangeCheckbox = e => {

    setForm({
      ...form,
      [e.target.name]: e.target.checked
    });
  };

  let userInfoLocal = useCallback(
    (info) => {
      window.localStorage.setItem(singInObject.singInInfo, JSON.stringify(info));
    },
    [],
  )



  async function handleSubmit(event) {
    event.preventDefault();

    const myData = await apiEventService.auth.userLogin(form)
    if (myData.status === 200) {
      const info = {
        jwt: myData.data.jwt,
        username: form.username,
        admin: myData.headers['access-content'] === 'true' ? true : false,
        singIn: true
      }

      userInfoLocal(info)
      dispatch(userLogIn(info));
      setLoggedInSuccessAndRedirect(true)

    } else if (myData.status === 401) {
      setErrorMessage('Invalid credentials. Please try again.')
      // setUserInfo({})
      window.localStorage.removeItem(singInObject.singInInfo)
    } else if (myData.status === 403) {
      setErrorMessage('You need to verify the email ')

      userMail(myData.error.maskEmail)
    }
    else {
      setErrorMessage('Oops, something is wrong. Please try again later. ');
    }
  }

  if (loggedInSuccessAndRedirect) {
    return (
      <Redirect to={appLinks.homePageLink} />
    )
  } else if (token !== null && token.jwt !== '') {
    // dispatch(fullPageYes())
    return (
      <SingOut />

    )
  } else if (usernameEmail.set) {
    return (
      <Redirect to={{ pathname:appLinks.verificationPageLink, state:{ maskEmail: usernameEmail.maskEmail, username: form.username } }} />
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <UserRegiLogPassHead headCaption="Sign in" />


        <div style={{ height: '65px' }}>
          {errorMessage === '' ? '' : <ThemeProvider theme={buttonFont}><Alert severity="warning" className={classes.warring}>{errorMessage}</Alert></ThemeProvider>}
        </div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={handleChange}
            onBlur={handleErrorUsername}
            helperText={errorMessageTextFields.username.message}
            error={errorMessageTextFields.username.status}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            onBlur={handleErrorPassword}
            helperText={errorMessageTextFields.password.message}
            error={errorMessageTextFields.password.status}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}

            name='remember'
            label="Remember me"
            onChange={handleChangeCheckbox}

          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
              <RouterLink to={appLinks.resetPasswordPageLink} variant="body2">
                {"Forgot password?"}
              </RouterLink>
                
              </Link>
            </Grid>
            <Grid item>
              <RouterLink to={appLinks.registerPageLink} variant="body2">
                {"Don't have an account? Sign Up"}
              </RouterLink>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}