// import { Chart, registerables } from 'chart.js';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

import { useEffect, useRef } from 'react'


const LineChart = ({ data }) => {

    const chartContainer = useRef()

    useEffect(() => {
        let myChart = null
        if (chartContainer && chartContainer.current) {
            myChart = new Chart(chartContainer.current, {
                type: 'line',
                data: data,
                options: {
                    aspectRatio:4, //(width/height)
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'day'
                            },
                            displayFormats: {
                                day: 'MMM DD'
                            }
                        },
                        y: {
                            suggestedMin: 50,
                            suggestedMax: 140,
                        }
                    },
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    plugins: {
                        tooltip: {
                            position: 'nearest',
                            backgroundColor: 'rgb(0, 0, 35)',
                            bodyFont: {
                                size: 12,
                                weight: 'bold'
                            }
                        }
                    }
                },

            });
        }
        return () => {
            myChart.destroy()
        }
    }, [chartContainer, data])


    return (
        <div style={{ position: 'relative', height:'auto', width:'100%', margin: '30px 0' }}>
            <canvas ref={chartContainer}></canvas>

        </div>


    )
}

export default LineChart