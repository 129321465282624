import { useEffect, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch } from 'react-redux';
import { fullPageYes } from '../../../actions'

import TipCard from './TipCard'
import { Alert, AlertTitle } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import { apiEventService } from '../../utils/api/apiEventService'

const useStyles = makeStyles((theme) => ({
    homeRoot: {
        flexGrow: 1,
        marginBlock: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    alter: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const alterMessage = (message = '', title = 'Error', severity = 'error') => {
    return (

        <Alert severity={severity} >
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert >
    )
}

const HomePage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [tipsData, setTipsData] = useState([]);
    const [tipsError, setTipsError] = useState({});
    const [loaded, setLoaded] = useState(false)
    const getLoadData = useCallback(async () => {
        const tips = await apiEventService.getData.getRandomTips(6);
        if (tips.data === "") {
            setTipsError(tips)
        } else {
            setTipsData(tips.data._embedded.tips)
        }
        setLoaded(true);
        //
    }, [])

    useEffect(() => {
        // getLoadData();
        getLoadData();
    }, [getLoadData])


    useEffect(() => {
        dispatch(fullPageYes())
        // return () => {
        //     cleanup
        // }
    }, [dispatch])

    const contentTipsMessage = (content = <h2>No Content</h2>) => {
        return (
            <Grid item xs={12}>

                <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {content}
                    <Container maxWidth="sm">
                        {tipsData.length === 0 ? <img alt='No Content' src={process.env.PUBLIC_URL + '/images/sorryNoContent.gif'} style={{ width: '100%', height: 'auto' }} /> : ''}
                    </Container>
                </div>

            </Grid>
        )
    }

    const contentTips = () => {

        if (tipsData.length > 0) {
            return listItems
        } else if (tipsData.length === 0) {
            return contentTipsMessage();
        } else {
            return contentTipsMessage(<div className={classes.alter}>{alterMessage(tipsError.error + ' - ' + tipsError.message)}</div>)
        }

    }

    const listItems = tipsData.map((tip, index) => {
        return (
            <Grid item xs={12} sm={9} md={6} lg={4} key={index} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <TipCard tip={tip} />
            </Grid>
        )

    })
    if (loaded === false) {
        return (
            <div>lauding ...</div>
        )
    }

    return (
        <div>


            {/* <img src={process.env.PUBLIC_URL + "images/products2.svg"} alt="Prpducts" /> */}
            <div className={classes.homeRoot}>
                <Grid container spacing={3}>
                    {/* {listItems} */}
                    {/* { tipsData.length > 0 ?  listItems : <div className={classes.alter}>{alterMessage(tipsError.error + ' - ' + tipsError.message)}</div>} */}
                    {contentTips()}
                </Grid>
            </div>

        </div >

    );
}

export default HomePage
