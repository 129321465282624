
const port = window.location.port === 80 ? '' : `:${window.location.port}`
export const appLinks = {
  // webLink: 'http://localhost:3000',
  webLink: window.location.protocol + '//' + window.location.hostname + port,
  homePageLink: '/',
  // productsPageLink: '/products',
  // addProductPageLink: '/product-add',
  accountPageLink: '/account',
  profilePageLink: '/profile',
  signInLink: '/sigIn',
  registerPageLink: '/register',
  addTipPageLink: '/tip',
  tipsPageLink: '/tips',
  bloodPressuresPageLink: '/pressures',
  verificationPageLink: '/verification',
  resetPasswordPageLink: '/resetPassword',
}

export const singInObject = {
  singInInfo: 'singInInfo',
  verifyToken: 'verifyToken',
}


// export const baseApiUrl = 'https://' + window.location.hostname + ':8443/'
export const baseApiUrl = appLinks.webLink + '/'