import { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';



const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(5),
            width: 200,
            // color: 'rgba(255, 0, 0, 0.54)',
        },
    }

}));


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    const MAX_VAL = 999000;
    const withValueLimit = (inputObj) => {
        const { value } = inputObj;
        if (value < MAX_VAL) return inputObj;
    };


    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            // prefix="$"
            format="### ###"
            isAllowed={withValueLimit}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


const VerificationForm = (props) => {
    const classes = useStyles();
    // const classes = stylesSignReg();
    const [values, setValues] = useState({
        // numberformat: '',
    });

    const inputEl = useRef(null);
    // const invalidcode = useRef(false);
    const [invalidCode, setInvalidCode] = useState(false)


    useEffect(() => {
        if (props.invalidcode !== undefined && props.invalidcode) {
            // invalidcode.current = props.invalidcode
            setInvalidCode(props.invalidcode)
        }
        return () => {
            // invalidcode.current = false
        }
    }, [invalidCode, props.invalidcode])

    const handleChange = (e) => {
        // e.preventDefault()
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
        inputEl.current = e.target.value
        setInvalidCode(false)
        props.onChange(inputEl.current)
    }

    return (
        <>
            <div style={{marginBottom: 10}}>Please enter the code in the form</div>
            <form className={classes.form} noValidate>
                <TextField error = {invalidCode}
                    ref={inputEl}
                    label="Code"
                    value={values.numberformat}
                    onChange={handleChange}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    variant="outlined"
                    helperText={invalidCode ? 'Incorrect number.' : <>&nbsp;</>}
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                />
            </form>
        </>
    )
}

export default VerificationForm
