import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, Link as RouterLink  } from "react-router-dom";
import { appLinks } from "../../utils/appLinks";
import { fullPageNo } from "../../../actions";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import VerificationForm from "./VerificationForm";
import Copyright from "../../utils/Copyright";
import UserRegiLogPassHead from "../../utils/UserRegiLogPassTitle";
import { stylesSignReg } from "../../utils/themes/stylesSignReg";
import { apiEventService } from "../../utils/api/apiEventService";
import EmailIcon from "@material-ui/icons/Email";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ["Send email", "Submit Code to activate account"];
}

const buttonText = ["Send email to get code", "Submit Code"];
const buttonIcons = [<EmailIcon />, ""];
async function sendEmail(username = "") {
  // e.preventDefault()

  const formInitial = {
    username: username,
    password: "",
  };
  const sendEmailForCode = await apiEventService.auth.sendMail(formInitial);

  return sendEmailForCode;
}

const GetStepContent = (props) => {
  //    const dataIn = useRef(0)

  const eventhandler = (data) => {
    props.parentCallback(data);
  };
 
  switch (props.step) {
    case 0:
      return (
        <>
          To complete the verification process, we'll send you to{" "}
          <b>{props.maskEmail}</b> verification code.
        </>
      );
    case 1:
      return(
        <VerificationForm onChange={eventhandler} invalidcode={props.invalidcode}/>
      )
    default:
      return "Unknown step";
  }
};



export default function VerificationPage(props) {
  const classes = useStyles();
  const classesSingin = stylesSignReg();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const fullPage = useSelector((state) => state.fullPage);
  const [token, setToken] = useState("");
  const [accountAlreadyActive, setAccountAlreadyActive] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);

  // const [values, setValues] = useState({
  //     textmask: '(1  )    -    ',
  //     numberformat: '1320',
  //   })

  const childRef = useRef(0);

  useEffect(() => {
    if (fullPage) dispatch(fullPageNo());
  }, [dispatch, fullPage]);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const callbackFunction = (childData) => {

    childRef.current = childData;
    setInvalidCode(false)
  };

  const handleNext = async () => {
    let increase = false;


    if (activeStep === 0) {
      const token = await sendEmail(state.username);
      setToken(token.data.jwt);
      increase = true;
    } else if (activeStep === 1) {
      const activateAccount = await apiEventService.auth.confirm({
        data: JSON.stringify({ code: parseInt(childRef.current) }),
        token: token,
      });
      if (activateAccount.status === 200) {
        increase = true;
      } else if (activateAccount.status === 406) {
        increase = true;
        setAccountAlreadyActive(true);
      } else if (activateAccount.status === 401){
        setInvalidCode(true)
      }
    }


    if (increase) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  if (state === undefined || state.username === null || state.username === "") {
    return (
      <div>
        <Redirect to={appLinks.signInLink} />
      </div>
    );
  }

  const buttonNext = (activestep, steps) => {
    return (
      <Button
        variant="contained"
        color="primary"
        endIcon={buttonIcons[activestep]}
        onClick={() => {
          handleNext();
        }}
        activeStep={activestep}
        steps={steps}
        className={classes.button}
      >
        {activestep === steps.length - 1
          ? buttonText[1]
          : buttonText[activestep]}
      </Button>
    );
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classesSingin.paper}>
        <UserRegiLogPassHead headCaption="Verify email" />
      </div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography component={"span"} variant={"body2"}>
                <GetStepContent
                  parentCallback={callbackFunction}
                  step={index}
                  maskEmail={state.maskEmail}
                  invalidcode={invalidCode}
                //   accountAlreadyActive={accountAlreadyActive}
                />
              </Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleNext();
                    }}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1
                      ? buttonText[1]
                      : buttonText[activeStep]}
                  </Button> */}
                  {buttonNext(activeStep, steps)}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>
            {accountAlreadyActive ? (
              "The account does not need to be activated because it is active."
            ) : (
              <>
                All steps completed - <b>{state.username}</b>, you activated
                your account
              </>
            )}
          </Typography>
          {accountAlreadyActive ? (
            ""
          ) : (
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          )}
          <Button
            component={RouterLink}
            to={appLinks.signInLink}
            className={classes.button}
            startIcon={<AccountCircleIcon />}
            variant="contained"
            color="primary"
          >
            Sign in{" "}
          </Button>
        </Paper>
      )}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
