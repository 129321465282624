import axios from 'axios'
import { useState, useCallback, useEffect } from 'react'
import { baseApiUrl } from '../appLinks'

// const port = window.location.port === 80 ? '' : `:${window.location.port}`


const apiClient = axios.create({
    // baseURL: window.location.protocol+'//'+window.location.hostname+port+'/rest', 
    baseURL: baseApiUrl + 'rest',
})

const Header = (token) => {

    if (token === null) {
        return {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
        };
    }
    return (
        {
            Authorization: "Bearer " + token,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

        }
    );
}

export const apiEventService = {

    auth: {
        userLogin(payload) {
            return myAxios('/login', payload, Header(null), 'post')
        },
        addAppUser(payload) {
            return myAxios('/v1/members', payload.data, Header(null), 'post')
        },
        addBPressure(payload) {
            return apiClient.post('/v1/pressures', payload.data, {
                headers: Header(payload.token)
            });
        },
        addTip({ data = null, token = '' }) {
            // return apiClient.post('/v1/tips', payload.data, {
            //     headers: Header(payload.token)
            // });
            return myAxios('/v1/tips', data, Header(token), 'post')
        },
        sendMail(payload) {
            return myAxios('/sendEmail', payload, Header(null), 'post')
        },
        confirm({ data = null, token = '' }) {
            return myAxios('/confirm', data, Header(token), 'post')
        },
        sendEmailR({ data }) {
            return myAxios('/sendEmailR', data, Header(null)
                , 'post')
        },
        resetPassword({ data }) {
            return myAxios('/resetPassword', data, Header(null)
                , 'post')
        }

    },
    getData: {
        getPressureWithUserTokenAndDays({ page = 0, rowsPerPage = 10, orderBy = 'measurementDate', order = 'DESC', token = null }) {

            return myAxios('v1/pressure?days=120&size=1500&sort=measurement_date%2CDESC', '', Header(token), 'get')

        }, // end getPressureUserToken
        getPressureWithUserToken({ page = 0, rowsPerPage = 10, orderBy = 'measurementDate', order = 'DESC', token = null }) {

            return myAxios(`v1/userpressures?page=${page}&size=${rowsPerPage}&sort=${orderBy},${order}`, '', Header(token), 'get')

        }, // end getPressureWithUserToken
        getRandomTips(payload) {
            let limit;
            payload === undefined ? limit = '?limit=6' : limit = '?limit=' + payload;

            return myAxios('/v1/tips/search/random' + limit, '', Header(null), 'get')
        },
        getTips({ token = null }) {
            // return apiClient.get('/v1/tips', {
            //     headers: Header(payload.token),
            // });
            return myAxios('/v1/tips', '', Header(token), 'get')
        },
         downloadExcel({ token = null }) {
           
            let data = apiClient({
                method: 'get',
                url: '/download/blood_pressure_data.xlsx',

                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: "blob",
            })
          
            return data;


        },
    }
}


const myAxios = (url, payload = '', headers = Header(null), methods = 'get') => {
    try {
        return apiClient(
            {
                method: methods,
                url: url,
                headers: headers,
                data: payload
            }

        ).then(response => {
            // console.log(response.data);
            // console.log(response.status);
            // console.log(response.statusText);
            // console.log(response.headers);
            // console.log(response.config);
            return {
                data: response.data,
                headers: response.headers,
                status: response.status,
                error: null
            };
        })
            .catch(error => {
                // console.log(error);
                if (error.response) {
                    // console.log(error.response);
                    return {
                        data: '',
                        headers: error.response.headers,
                        status: error.response.status,
                        error: error.response.data
                    };
                } else if (error.request) {

                    return {
                        data: '',
                        headers: '',
                        status: null,
                        error: 'try later'
                    }
                } else {

                    return {
                        data: '',
                        headers: '',
                        status: null,
                        error: error.message
                    }
                }
            })
    } catch (error) {
        // console.log(error);
        return {
            data: '',
            headers: '',
            error: 'try later'
        }
    } // end catch


}// end addAppUser





// Hook
export const useAsync = (asyncFunction, form, immediate = true) => {
    const [status, setStatus] = useState("idle");
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);

    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.


    const execute = useCallback(() => {
        setStatus("pending");
        setValue(null);
        setError(null);
        // console.log(form);
        return asyncFunction(form)

            .then((response) => {
                // console.log(form);
                setValue(response);
                setStatus("success");
            })
            .catch((error) => {
                // console.log(error);
                setError(error);
                setStatus("error");
            });
    }, [asyncFunction, form]);

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);

    return { execute, status, value, error };
};