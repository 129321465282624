import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import {appLinks} from '../utils/appLinks'
import { pageInfo } from './details'

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align='center'>
            {'Copyright © '}
            <Link color="inherit" href={appLinks.webLink}>
                { pageInfo.name }
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}
export default Copyright