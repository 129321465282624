

export const initialState = {
    email: '',
    token: '',
    password: '',
    reEnterPassword: '',
    error: {
        email: {
            value: '',
            status: false,
        },
        token: {
            value: '',
            status: false,
        },
        password: {
            value: '',
            status: false,
        },
        reEnterPassword: {
            value: '',
            status: false,
        },
    }
};



export function reducer(state, action) {
    switch (action.type) {
        case 'email':
            return {
                ...state,
                email: action.payload
            }
        case 'resetPassword':
            return {
                ...state,
                token: action.token,
                password: action.password,
                reEnterPassword: action.reEnterPassword,
            }
        case 'errorPassword':
            return {
                ...state,
                error: {
                    ...state.error,
                    password: {
                        value: action.errorPasswordValue,
                        status: action.errorPasswordStatus,
                    }
                }
            }
        case 'errorReEnterPassword':
            return {
                ...state,
                error: {
                    ...state.error,
                    reEnterPassword: {
                        value: action.errorReEnterPasswordValue,
                        status: action.errorReEnterPasswordStatus,
                    }
                }
            }
        case 'errorToken':
            return {
                ...state,
                error: {
                    ...state.error,
                    token: {
                        value: action.errorTokenValue,
                        status: action.errorTokenStatus,
                    }
                }
            }
            case 'errorStage2':
                return {
                    ...state,
                    error: {
                        ...state.error,
                        token: {
                            value: action.errorTokenValue,
                            status: action.errorTokenStatus,
                        },
                        password: {
                            value: action.errorPasswordValue,
                            status: action.errorPasswordStatus,
                        },
                        reEnterPassword: {
                            value: action.errorReEnterPasswordValue,
                            status: action.errorReEnterPasswordStatus,
                        },

                    }
                }
        default:
            throw new Error();
    }
}