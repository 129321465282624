import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { useDispatch } from 'react-redux';
// import { userLogIn, isAdminYes, isAdminNo, userLogOut } from '../../actions';
import { userLogIn, userLogOut } from '../../actions'
// import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { stylesSignReg } from '../utils/themes/stylesSignReg';
import { tokenObject } from "../utils/details";
// import { dataLoaderPost, links } from '../utils/dataLoader';
// import { singInObject } from '../utils/details';

import { apiEventService } from '../utils/api/apiEventService';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const formInitial = {
    username: "",
    password: "",
    remember: true,
}

const errorMessageTextFieldsInitial = {
    username: {
        message: ' ',
        status: false
    },
    password: {
        message: ' ',
        status: false
    },
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


/**
 * 
 * @param {*} props 
 * @returns 
 */
function SingInDialog(props) {
    // const classes = useStyles();
    const { onClose, open } = props;
    const classes = stylesSignReg();
    const dispatch = useDispatch();
    const [fromPropsMessage, setFromPropsMessage] = useState(false)
    let singInData = tokenObject;
    const inputRef = React.useRef();
    const [form, setForm] = React.useState(formInitial);
    let [counter, setCounter] = React.useState({ value: 1 });

    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorMessageTextFields, setErrorMessageTextFields] = React.useState(errorMessageTextFieldsInitial);


    // React.useEffect(() => {

    //     return () => {
    //         setFromPropsMessage(null)
    //     }
    // }, [])


    const handleClose = () => {

        // onClose(selectedValue);
        onClose(false)
    };


    let errorMessageText = {
        username: 'Username has a minimum of 6 characters.',
        password: 'Password has a minimum of 8 characters. '
    }


    let handleErrorUsernameHelper = (message, status) => {
        setErrorMessageTextFields((prevState) => ({
            ...prevState,
            username: {
                message: message,
                status: status
            },
        }))
    };

    let handleErrorPasswordHelper = (message, status) => {
        setErrorMessageTextFields((prevState) => ({
            ...prevState,
            password: {
                message: message,
                status: status
            },
        }))
    };

    let handleErrorUsername = e => {
        if (form.username.length < 6) {
            handleErrorUsernameHelper(errorMessageText.username, true);
        } else {
            handleErrorUsernameHelper(" ", false);
        }
    }

    let handleErrorPassword = e => {
        if (form.password.length < 8) {
            handleErrorPasswordHelper(errorMessageText.password, true);
        } else {
            handleErrorPasswordHelper(' ', false);
        }
    }

    let handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "username") {
            if (errorMessageTextFields.username.status && e.target.value.length < 6) {
                handleErrorUsernameHelper(errorMessageText.username, true);
            } else {
                handleErrorUsernameHelper(" ", false);
            }
        } else if (e.target.name === "password") {
            if (errorMessageTextFields.password.status && e.target.value.length < 8) {
                handleErrorPasswordHelper(errorMessageText.password, true);
            } else {
                handleErrorPasswordHelper(" ", false);
            }
        }
    }

    const handleChangeCheckbox = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        });
    };

    // const loginRequest = (data, url) => {
    //     return dataLoaderPost(data, url);
    // }

    async function handleSubmit(event) {
        event.preventDefault();

        // let userToken = await loginRequest(form, links.login);

        let userToken = await apiEventService.auth.userLogin(form)


        if (userToken === undefined) {
            setErrorMessage('Oops, something is wrong. Please try again later. ');
        } else if (userToken.data !== '') {
            singInData = {
                username: form.username,
                jwt: userToken.data.jwt,
                singIn: true,
                admin: userToken.headers["access-content"] === 'true' ? true : false
            }
            dispatch(userLogIn(singInData));


            setErrorMessage('');
            if (form.remember === true) {
                window.localStorage.setItem("singInInfo", JSON.stringify(singInData));

            } else {
                window.localStorage.removeItem("singInInfo");
            }
            // setLoggedInSuccessAndRedirect(true);
            handleClose();
        } else {

            if (counter.value > 2) {
                // dispatch(isAdminNo());
                // window.localStorage.setItem("access-content", JSON.stringify(false));
                //  window.localStorage.removeItem(String(singInObject.singInInfo));
                window.localStorage.removeItem("singInInfo");
                setTimeout(() => dispatch(userLogOut()), 100);
            }
            setErrorMessage('username and / or password are incorrect ');
            setFromPropsMessage(true);
            setCounter({ value: counter.value + 1 });
        }
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                SingIn Dialog
        </DialogTitle>
            <DialogContent dividers>
                <div /*style={{ height: '65px' }}*/>
                    {errorMessage === '' ? '' : <Alert severity="warning">{errorMessage}</Alert>}
                    {fromPropsMessage === true || props.message === undefined || props.message === '' ? '' : <Alert severity="warning">{props.message}</Alert>}
                </div>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        inputRef={inputRef}
                        autoFocus={true}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="User name"
                        name="username"
                        autoComplete="username"
                        onChange={handleChange}
                        onBlur={handleErrorUsername}
                        helperText={errorMessageTextFields.username.message}
                        error={errorMessageTextFields.username.status}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                        onBlur={handleErrorPassword}
                        helperText={errorMessageTextFields.password.message}
                        error={errorMessageTextFields.password.status}
                    />
                    <FormControlLabel
                        checked={form.remember}
                        control={<Checkbox name="remember" color="primary" />}
                        onChange={handleChangeCheckbox}
                        label="Remember me"
                    />

                </form>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleSubmit} color="primary">
                    Sign In
          </Button>
            </DialogActions>
        </Dialog>
    );
}

SingInDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    // selectedValue: PropTypes.string.isRequired,
}


export default SingInDialog;