import { tokenVerify } from '../components/utils/details'
const defaultAction = {
    type: 'VERIFY_NO',
    payload: tokenVerify
  }


function tokenVerifyReducer(state = tokenVerify, action=defaultAction ) {

    switch (action.type) {
        case 'VERIFY_TOKEN':
            if (action.payload === undefined) {
                return state;
            }
            return action.payload;
        case 'VERIFY_EMPTY':
            return tokenVerify;
        default:
            return state;
    }
}

export default tokenVerifyReducer;