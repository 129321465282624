import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Moment from 'moment'
// import Button from '@material-ui/core/Button';
import TipDialogs from './TipDialogs';

import { baseApiUrl } from '../../utils/appLinks'


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '60%',
        flexShrink: 0,
        fontWeight: 'bold',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        marginLeft: 'auto',
        marginRight: '25px',
    },
    image: {
        maxHeight: '200px',
        float: 'right',
    },
    content: {
        width: '100%',
    }
}));


/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function AdminListTips({ tips, page }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    // const [open, setOpen] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const ListTips = () => {
        let num = 0;
        const genListTips = tips.map((tip) => {
            num++
            return (
                <Accordion expanded={expanded === 'panel' + num} key={'panel' + num.toString()} onChange={handleChange('panel' + num)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography className={classes.heading}>{tip.title}</Typography>
                        <Typography className={classes.secondaryHeading}>
                           {Moment(tip.createdOn).format('ddd MM/DD/YYYY')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.content}>
                            
                                <img src={baseApiUrl + tip.healthTipImage} alt={' '} className={classes.image} />
                                {tip.content}  
                                </Typography>
                          

                    </AccordionDetails>
                </Accordion>
            )

        })
        return (
            <div className={classes.root}>
                {genListTips}
            </div>
        )
    }
    return (
        <div className={classes.root}>
            <>

                <h3>Health Tips</h3>
                <TipDialogs />
                <ListTips />
               
            </>

        </div>
    );
}