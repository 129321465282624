
import { useEffect, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import FooterApp from './components/footer/FooterApp'
import PrimaryAppBar from './components/menu/appBar/PrimaryAppBar'
import { Switch, Route, Redirect } from "react-router-dom"
import HomePage from './components/pages/home/HomePage'
import SignInPage from './components/pages/sing/singIn/SignInPage'
import BloodPressures from './components/pages/bloodPressure/BloodPressures'
import RegisterPage from './components/pages/register/RegisterPage'
import ResetPasswordPage from './components/pages/resetPassword/ResetPasswordPage'
import TipsPage from './components/pages/tips/TipsPage'
import AccountPage from './components/pages/account/AccountPage'
import ProfilePage from './components/pages/profile/ProfilePage'
import VerificationPage from './components/pages/verification/VerificationPage'
import NoMatch from './components/pages/errors/NoMatch'
import { userTheme, adminTheme } from './components/utils/themes/appTheme'
import { appLinks, singInObject } from './components/utils/appLinks'

import { useSelector, useDispatch } from 'react-redux';
import './App.scss'

import { userLogIn, userLogOut } from './actions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: 360,
  },
  main: {
    // marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    // paddingLeft: theme.spacing(1) / 2,
    // paddingRight: theme.spacing(1) / 2,
    paddingLeft: 0,
    paddingRight: 0,
  },

  pageContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  loading: {
    textAlign: 'center',
    height: '90vh',
    padding: '40% 0',
  }
}));



function App() {
  const userTheme2 = createMuiTheme(userTheme);
  const adminTheme2 = createMuiTheme(adminTheme);
  const classes = useStyles();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true)

  const fullPage = useSelector(state => state.fullPage);
  const user = useSelector(state => state.token);

  useEffect(() => {

    const formLocalStorage = async () => {
      const userInfo = await JSON.parse(window.localStorage.getItem(singInObject.singInInfo))

      if (userInfo === null || userInfo.jwt === '') {
        dispatch(userLogOut())
      } else {
        dispatch(userLogIn(userInfo))
      }
      setLoading(false)
    }
    formLocalStorage()
    return () => {

    }
  }, [dispatch])

  if (loading) {
    return (
      <div className={classes.loading}>
        <h4>Loading ...</h4>
      </div>

    )
  }

  return (
    <ThemeProvider theme={user !== null && user.admin === true ? adminTheme2 : userTheme2}>

      <div className={classes.root}>
        <CssBaseline />
        <Container component="main" className={classes.main} maxWidth="lg">
          {fullPage ? <PrimaryAppBar display="block" displayPrint="none" /> : ''}
          <Container className={classes.pageContainer} >
            <Switch>
              <Route exact path={appLinks.homePageLink}>
                <HomePage />
              </Route>
              <Route path={appLinks.signInLink}>
                <SignInPage />
              </Route>
              <Route path={appLinks.registerPageLink}>
                <RegisterPage />
              </Route>
              <Route path={appLinks.verificationPageLink}>
                <VerificationPage />
              </Route>
              <Route path={appLinks.resetPasswordPageLink}>
                <ResetPasswordPage />
              </Route>
              <Route path={appLinks.bloodPressuresPageLink}>
                {user !== null && user.singIn ? <BloodPressures /> : <Redirect to={appLinks.homePageLink} />}
              </Route>
              <Route path={appLinks.accountPageLink}>
                {user !== null && user.singIn ? <AccountPage /> : <Redirect to={appLinks.homePageLink} />}
              </Route>
              <Route path={appLinks.profilePageLink}>
                {user !== null && user.singIn ? <ProfilePage /> : <Redirect to={appLinks.homePageLink} />}
              </Route>
              <Route path={appLinks.tipsPageLink}>
                {user !== null && user.admin ? <TipsPage /> : <Redirect to={appLinks.homePageLink} />}
              </Route>


              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Container>
        </Container>
        {fullPage ? <FooterApp /> : ''}
      </div>

    </ThemeProvider>
  );
}

export default App;
