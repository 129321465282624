import { useState, useRef } from 'react'
import { makeStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  divMarginBottom: {
    marginBottom: theme.spacing(10)
  }
}));

const ResetPasswordForm = (props) => {
  const classes = useStyles();
  // const [email, setEmail] = useState('');
  const [form, setForm] = useState({
    token: '',
    password: '',
    reEnterPassword: '',
    error: {
      token: {
        value: '',
        status: false,
      },
      password: {
        value: '',
        status: false,
      },
      reEnterPassword: {
        value: '',
        status: false,
      },
    }

  });
  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // const [error, setError] = useState({ status: false, message: '' });
  const inputEl = useRef(null);


  const handleChange = e => {

    inputEl.current = {
      ...form,
      [e.target.name]: e.target.value
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });

    props.onChange(inputEl.current)
  };



  return (
    <>

      <form className={classes.root} noValidate>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span className={classes.divMarginBottom}>Please the token and new password to reset the password.</span>
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              error={props.state.error.token.status}
              required
              fullWidth
              name='token'
              autoFocus
              // className={classes.margin}
              label="Token"
              variant="outlined"
              id="mui-theme-provider-outlined-input-token"
              value={form.token}
              onChange={handleChange}
              helperText={props.state.error.token.status ? props.state.error.token.value : <>&nbsp;</>}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              error={props.state.error.password.status}
              label='New Password'
              variant="outlined"
              fullWidth
              required
              name="password"
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              value={form.password}
              onChange={handleChange}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              helperText={props.state.error.password.status ? props.state.error.password.value : <>&nbsp;</>}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              error={props.state.error.reEnterPassword.status}
              label='Re-enter new password '
              variant="outlined"
              fullWidth
              required
              name="reEnterPassword"
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              value={form.reEnterPassword}
              onChange={handleChange}
              // InputProps={{ // <-- This is where the toggle button is added.
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton
              //         aria-label="toggle re password visibility"
              //         onClick={handleClickShowPassword}
              //         onMouseDown={handleMouseDownPassword}
              //       >
              //         {showPassword ? <Visibility /> : <VisibilityOff />}
              //       </IconButton>
              //     </InputAdornment>
              //   )
              // }}
              helperText={props.state.error.reEnterPassword.status ? props.state.error.reEnterPassword.value : <>&nbsp;</>}
            />
          </Grid>
          {/* <Grid item xs={12} md={7}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={form.password}
              onChange={handleChange}
              helperText={error.status ? error.message : <>&nbsp;</>}
            // error={formError.password.status}
            // helperText={formError.password.message}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="reEnterPassword"
              label="Re-enter password "
              type="password"
              id="reEnterPassword"
              autoComplete="current-password"
              value={form.reEnterPassword}
              onChange={handleChange}
              helperText={error.status ? error.message : <>&nbsp;</>}
            // error={formError.reEnterPassword.status}
            // helperText={formError.reEnterPassword.message}
            />
          </Grid> */}
        </Grid>
      </form>
    </>
  )
}

export default ResetPasswordForm
