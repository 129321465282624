import { useState, useRef, useEffect } from 'react'
import { makeStyles, } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  divMarginBottom: {
    marginBottom: theme.spacing(10)
  }
}));

const EmailForm = (props) => {
  const classes = useStyles();
  // const [email, setEmail] = useState('sbudai@hotmail.com');
  const [error, setError] = useState({ status: false, message: '' });
  const inputEl = useRef(null);
  // const errorRef = useRef(false)


  const handleChange = (event) => {
    // setEmail(event.target.value);
    setError({ status: false, message: '' })
    inputEl.current = event.target.value
    props.onChange(inputEl.current)
  };

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  useEffect(() => {

    if (props.errorData.status === 200 || props.errorData.status === 201 || props.errorData.status === '') {
      setError({ status: false, message: '' });
    } else {
      setError({ status: true, message: props.errorData.error.message });
    }
  }, [setError, props.errorData])
 
  return (
    <div>
      <div className={classes.marginBottom}>Please enter your Email address to send you a token to reset the password.</div>
      <form className={classes.root} noValidate onSubmit={handleSubmit} >
        <TextField
          error={error.status}
          required
          className={classes.margin}
          label="Email"
          variant="outlined"
          id="mui-theme-provider-outlined-input"
          value={props.state.email}
          onChange={handleChange}
          helperText={error.status ? error.message : <>&nbsp;</>}
        />
      </form>
    </div>
  )
}

export default EmailForm
